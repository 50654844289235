<template>
  <div class="content" :class="{ single: $route.path.includes('map')}">
    <div class="more" v-if="isMore">
      <div class="title">
        {{ moreTitle }}
        <a href="javascript:void(0);" @click="isMore = false;">返回</a>
        <a v-if="'船舶管理,进度管理'.indexOf(moreTitle) > -1 && moreIndex < parseInt((showCount + showPageRows - 1) / showPageRows)" href="javascript:void(0);" @click="preNextPage(moreIndex+1)">下一页</a>
        <a v-if="'船舶管理,进度管理'.indexOf(moreTitle) > -1 && moreIndex > 1" href="javascript:void(0);" @click="preNextPage(moreIndex-1)">上一页</a>
      </div>
      <div class="more-content">
        <keep-alive>
          <component ref="moreComponent" :is="moreComponent" :show-count.sync="showCount" />
        </keep-alive>
      </div>
    </div>
    <audio autoplay @ended="playsouding = false" v-if="playsouding">
      <source v-for="(item,key) in audios" :key="key" :src="item.src" :type="item.type"/>
    </audio>
    <el-dialog id="playerVideo" title="随机巡查" :visible.sync="playVideoDialog" :before-close="handlePlayVideoClose">
      <player :id="playerId" :title="playerTitle" />
    </el-dialog>
    <div class="left" v-if="leftLoad" :style="{ display: leftHidden ? 'none' : 'flex' }">
      <div class="item">
        <div class="title">安全管理
        <!--<a href="javascript:void(0);" @click="isMore = true; moreTitle = '进度管理'; moreComponent = 'SecurityCheck'">查看更多</a>-->
        </div>
        <div class="item-content">
          <div class="security">
							<div class="left">
								<div class="security-echarts">

								</div>
								<div class="security-echarts">

								</div>
							</div>
							<div class="middle">
								<div class="row">
									<span>开工日期</span>:
									<font class="date">{{ bidSectionData.value2 }}</font>
								</div>
								<div class="row">
									<span>施工总工期</span>:
									<font class="num" :style="{ width: bidSectionData.value1 > 999 ? '35px' : '25px' }">{{ bidSectionData.value1 }}</font>天
								</div>
								<div class="row">
									<span>已施工天数</span>:
									<font class="num">{{ bidSectionData.value3 }}</font>天
								</div>

								<div class="row" style="margin-top: 10px">
									<span>安全检查次数</span>:
									<font class="num">{{ securityData.inspectNum }}</font>次
								</div>
								<div class="row">
									<span>发现问题次数</span>:
									<font class="num">{{ securityData.questionNum }}</font>次
								</div>
								<div class="row">
									<span>整改完成次数</span>:
									<font class="num">{{ securityData.completeNum }}</font>次
								</div>
							</div>
							<div class="right">
								<!--<div class="row">
									<span>已施工天数</span>:
									<font class="num">{{ bidSectionData.value3 }}</font>天
								</div>-->
								<div class="row">
									<span>平安运行</span>:
									<font class="num">{{ bidSectionData.value4 }}</font>天
								</div>
								<div class="row">
									<span>平安运行率</span>:
									<font class="num">100%</font>
								</div>

								<div class="row" style="margin-top: 31px">
									<span class="education-training">安全教育培训次数</span>:
									<font class="num">{{ securityData.trainNum }}</font>次
								</div>
								<div class="row">
									<span class="education-training">参加人数</span>:
									<font class="num">{{ securityData.trainPeople }}</font>次
								</div>

								<div class="row" style="margin-top: 10px">
									<span>安全交底次数</span>:
									<font class="num">{{ securityData.discloseNum }}</font>次
								</div>
								<div class="row">
									<span>参加人数</span>:
									<font class="num">{{ securityData.disclosePeople }}</font>次
								</div>
							</div>
              <div class="clear"></div>
						</div>
        </div>
      </div>
      <div class="item">
        <div class="title">
          质量管理
          <div class="switch">
            <div class="prev" @click="qualityRowIndex = 0">←</div>
            <div class="next" @click="qualityRowIndex = 1">→</div>
          </div>
        </div>
        <div class="item-content">
          <div class="quality">
							<div class="row" v-if="qualityRowIndex == 0">
								<div class="item">
									<div class="title">检查次数<font class="num" style="color: #03C054">{{ quailityData.inspectNum }}</font>次</div>
									<div class="quaility-echarts"></div>
									<div class="block">检查发现问题个数<font class="num">{{ quailityData.inspectQuestionNum }}</font>个</div>
									<div class="block"><span class="legend" style="background: #28F2E6;"></span>已整改问题个数<font class="num">{{ quailityData.rectificationCompletedNum }}</font>个</div>
								</div>
								<div class="item">
									<div class="title">督查次数<font class="num" style="color: #03C054">{{ quailityData.supervisionNum }}</font>次</div>
									<div class="quaility-echarts"></div>
									<div class="block">检查发现问题个数<font class="num">{{ quailityData.supervisionQuestionNum }}</font>个</div>
									<div class="block"><span class="legend" style="background: #28F2E6;"></span>已整改问题个数<font class="num">{{ quailityData.supervisionRectificationCompletedNum }}</font>个</div>
								</div>
								<div class="item">
									<div class="title">单位工程总数<font class="num" style="color: #03C054">{{ quailityData.unitEngineeringNum }}</font>个</div>
									<div class="quaility-echarts"></div>
									<div class="block"><span class="legend" style="background: #28F2E6;"></span>已验收数量<font class="num">{{ quailityData.unitAcceptanceNum }}</font>个</div>
									<div class="block"><span class="legend" style="background: #FD6500;"></span>验收不合格数量<font class="num">{{ quailityData.unitUnqualifiedNum }}</font>个</div>
									<div class="block"><span class="legend" style="background: #02E05D;"></span>已整改完成数量<font class="num">{{ quailityData.unitRectificationNum }}</font>个</div>
								</div>
							</div>
							<div class="row" v-if="qualityRowIndex == 1">
								<div class="item">
									<div class="title">隐蔽工程总数<font class="num" style="color: #03C054">{{ quailityData.concealmentEngineeringNum }}</font>个</div>
									<div class="quaility-echarts"></div>
									<div class="block"><span class="legend" style="background: #28F2E6;"></span>已验收数量<font class="num">{{ quailityData.concealmentAcceptanceNum }}</font>个</div>
									<div class="block"><span class="legend" style="background: #FD6500;"></span>验收不合格数量<font class="num">{{ quailityData.concealmentUnqualifiedNum }}</font>个</div>
									<div class="block"><span class="legend" style="background: #02E05D;"></span>已整改完成数量<font class="num">{{ quailityData.concealmentRectificationNum }}</font>个</div>
								</div>
								<div class="item">
									<div class="title">分部工程总数<font class="num" style="color: #03C054">{{ quailityData.branchEngineeringNum }}</font>个</div>
									<div class="quaility-echarts"></div>
									<div class="block"><span class="legend" style="background: #28F2E6;"></span>已验收数量<font class="num">{{ quailityData.branchAcceptanceNum }}</font>个</div>
									<div class="block"><span class="legend" style="background: #FD6500;"></span>验收不合格数量<font class="num">{{ quailityData.branchUnqualifiedNum }}</font>个</div>
									<div class="block"><span class="legend" style="background: #02E05D;"></span>已整改完成数量<font class="num">{{ quailityData.branchRectificationNum }}</font>个</div>
								</div>
								<div class="item">
									<div class="title">分项工程总数<font class="num" style="color: #03C054">{{ quailityData.subItemEngineeringNum }}</font>个</div>
									<div class="quaility-echarts"></div>
									<div class="block"><span class="legend" style="background: #28F2E6;"></span>已验收数量<font class="num">{{ quailityData.subItemAcceptanceNum }}</font>个</div>
									<div class="block"><span class="legend" style="background: #FD6500;"></span>验收不合格数量<font class="num">{{ quailityData.subItemUnqualifiedNum }}</font>个</div>
									<div class="block"><span class="legend" style="background: #02E05D;"></span>已整改完成数量<font class="num">{{ quailityData.subItemRectificationNum }}</font>个</div>
								</div>
							</div>
						</div>
        </div>
      </div>
      <div class="item">
        <div class="title">
          进度管理
          <a href="javascript:void(0);" @click="moreIndex = 1;showPageRows = 9;isMore = true; moreTitle = '进度管理'; moreComponent = 'ProgressCharts'">查看更多</a>
        </div>
        <div class="item-content">
          <common-progress-charts v-bind="commonProgressVhartsParams" v-if="commonProgressVhartsParams.totalPlannedDuration" />
        </div>
      </div>
      <div class="item">
        <div class="title">
          水位监测
          <a href="javascript:void(0);" @click="isMore = true; moreTitle = '水位监测'; moreComponent = 'WaterCharts'">全屏</a>
        </div>
        <div class="item-content">
          <component :is="'WaterCharts'" />
        </div>
      </div>
    </div>
    <div class="hidden-btn iconfont" :class="{ 'icon-shousuo': !leftHidden, 'icon-shousuo1' : leftHidden }" @click="leftHiddenClick" :style="leftHidden ? 'left: 0px' : 'left: 25%'"></div>
    <div class="map-div" :style="{ width: mapWidth }">
      <Map ref="map" :single-click="singleClick" :layer-menu="layerMenu" v-if="loadMap" />
    </div>
    <div class="hidden-btn iconfont" :class="{ 'icon-shousuo': rightHidden, 'icon-shousuo1' : !rightHidden }" @click="rightHiddenClick" :style="rightHidden ? 'right: 0px' : 'right: 25%'"></div>
    <div class="right" v-if="rightLoad" :style="{ display: rightHidden ? 'none' : 'flex' }">
      <div class="item">
        <div class="title">
          工地报警/标志提醒
          <div class="switch">
            <div class="prev" v-if="alarmIndex > 1" @click="alarmPrev">←</div>
            <div class="page">{{alarmIndex}}/{{alarmTotal}}</div>
            <div class="next" v-if="alarmHasNext" @click="alarmNext">→</div>
          </div>
        </div>
        <div class="item-content">
          <table class="alarm-table">
            <thead>
              <tr>
                <th>报警时间</th>
                <th>报警/标志提醒</th>
                <th>船舶名称</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(it, i) in alarmData" :key="i" :style="{color: it.processed ? 'unset': 'red'}">
                <td>{{ it.alarmTime }}</td>
                <td>{{ it.message || it.name }}</td>
                <td>{{ it.shipName || it.mmsi || '' }}</td>
                <td><span v-if="!it.processed" @click="processAlarm(it)">处理</span><span @click="positionAlarm(it)" :style="{ color: it.id == selectPositionAlarm.id ? 'yellow' : '' }">定位</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="item">
        <div class="title">人员管理</div>
        <div class="item-content">
          <div class="personnel-charts"></div>
          <div class="personnel-charts"></div>
        </div>
      </div>
      <div class="item">
        <div class="title">
          船舶管理
          <a href="javascript:void(0);" @click="moreIndex = 1;showPageRows = 12;isMore = true; moreTitle = '船舶管理'; moreComponent = 'ShipCharts'">查看更多</a>
        </div>
        <div class="item-content">
          <common-ship-charts @getShipsInfo="getShipsInfo" />
          <!-- <div class="ship-charts"></div>
          <div class="ship-charts"></div> -->
        </div>
      </div>
      <div class="item">
        <div class="title">
          材料管理
           <a href="javascript:void(0);" @click="isMore = true; moreTitle = '材料管理'; moreComponent = 'MaterialCharts'">查看更多</a>
        </div>
        <div class="item-content">
          <common-material-charts />
          <!-- <div class="material-echarts"></div>
          <div class="material-echarts"></div> -->
        </div>
      </div>
    </div>
    <el-dialog :title="shipTitle+'信息'" :visible.sync="shipVisible" width="95%">
      <el-table class="shipDialog" :data="shipDatas">
        <template v-for="(item,key) in shipColumn">
         <el-table-column :key="key" :width="item.width ? item.width :''" :prop="item.prop ? item.prop :''" :label="item.label ? item.label :''"/>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
  import Map from '@/components/Map/map'
  import Player from '@/Player'
  import * as echarts from 'echarts';
  import ProgressCharts from '../more/progress-charts'
  import SecurityCheck from '../more/security-check'
  import MaterialCharts from '../more/material-charts'
  import ShipCharts from '../more/ship-charts'
  import WaterCharts from '../more/water-charts'
  import CommonProgressCharts from '../charts/common-progress-charts'
  import CommonMaterialCharts from '../charts/common-material-charts'
  import CommonShipCharts from '../charts/common-ship-charts'

  var _this
  export default {
    components: {
      Map,
      Player,
      ProgressCharts,
      SecurityCheck,
      MaterialCharts,
      ShipCharts,
      WaterCharts,
      CommonProgressCharts,
      CommonMaterialCharts,
      CommonShipCharts
    },
    created() {
      this.$get('menu/getLayerMenu').then(res => {
        this.layerMenu = res.data
        this.loadMap = true
      })
      _this = this
    },
    async mounted() {
      await this.loadBidSectionData()
      setInterval(() => {
        this.loadAlarm();
      }, 5000)
      this.loadAlarm();
      //this.loadMaterialEcharts()
    },
    data() {
      return {
        showExpand: this.$route.path != '/map',
        selectPositionAlarm: {},
        playsouding: false,
        leftHidden: true,
        leftLoad: false,
        rightHidden: true,
        rightLoad: false,
        mapWidth: '100%',
        commonProgressVhartsParams: {
          name: ''
        },
        alarmData: [],
        bidSectionData: {},
        qualityRowIndex: 0,
        securityData: {},
        playerId: '',
        playerTitle: '',
        playVideoDialog: false,
        isMore: false,
        moreComponent: '',
        layerMenu: [],
        loadMap: false,
        alarmIndex: 1,
        alarmTotal:9999,
        alarmHasNext: false,
        audios: [
          {
            src: require('@/assets/audio/alarm.ogg'),
            type: 'audio/ogg'
          },
          {
            src: require('@/assets/audio/alarm.wav'),
            type: 'audio/wav'
          },
          {
            src: require('@/assets/audio/alarm.mp3'),
            type: 'audio/mp3'
          }
        ],
        shipTitle: '',
        shipVisible: false,
        shipDatas: '',
        shipColumn:[],
        moreIndex: 0,
        quailityData: {},
        showCount: 0,
        showPageRows:0
      }
    },
    methods: {
      alarmPrev(){
        if(this.alarmIndex > 1){
          this.alarmIndex = this.alarmIndex - 1;
          this.loadAlarm();
        }
      },
      alarmNext(){
        if(this.alarmHasNext){
          this.alarmIndex = this.alarmIndex + 1;
          this.loadAlarm();
        }
      },
      positionAlarm(alarm){
        this.selectPositionAlarm = alarm
        this.$refs.map.setMapCenter({
          center: [alarm.longitude / 600000, alarm.latitude / 600000],
          transform: true,
          zoom: 17
        },null,alarm.id, alarm.message ? true : false)
        console.log(alarm)
      },
      processAlarm (alarm){
        if(alarm && !alarm.processed) {
          this.$confirm('该报警信息是否已处理?', '提示', {
              confirmButtonText: '已处理',
              cancelButtonText: '未处理',
              type: 'warning'
          }).then(() => {
            this.$get(`/screen/processAlarm?id=${alarm.id}`).then(() => {
                alarm.processed = '1'
                this.$message({
                    type: 'success',
                    message: '处理成功!'
                });
                this.loadAlarm()
            })
          })
        }
      },
      updateMapDivSize(){
        if (this.leftHidden && this.rightHidden) {
          this.mapWidth = '100%'
        }else{
          if (this.leftHidden || this.rightHidden) {
            this.mapWidth = '75%'
          }else{
            this.mapWidth = '50%'
          }
        }
        if(this.mapWidth == '50%') {
					this.$refs.map.setBarShow(true)
				} else {
					this.$refs.map.setBarShow(false)
				}
      },
      leftHiddenClick(){
        this.leftHidden = !this.leftHidden
        this.updateMapDivSize()
        this.$refs.map.updateMapSize()
        if (!this.leftLoad) {
          this.leftLoad = true
          this.loadSecurityEcharts()
          this.$nextTick(() => { this.loadQualityCharts() })
        }
      },
      rightHiddenClick(){
        this.rightHidden = !this.rightHidden
        this.updateMapDivSize()
        this.$refs.map.updateMapSize()
        if (!this.rightLoad) {
          this.rightLoad = true
          this.$nextTick(() => { this.loadPersonnelShipCharts() })
        }
      },
      handlePlayVideoClose(){
        this.playerId = ''
        this.playerTitle = ''
        this.playVideoDialog = false
      },
      singleClick(type, data) {
        if (type === 'camera') {
          if (data.deviceStatus != 1) {
            this.$message({
              message: '摄像头不在线',
              type: 'warning'
            });
            return
          }
          this.playerId = data.cameraId
          this.playerTitle = data.name
          this.playVideoDialog = true
        }
      },
      async loadBidSectionData() {
		await this.$post('common/table/list', {
			type: 'biaoduan'
		}).then(res => {
			this.bidSectionData = res.data[0]
			var currentDate = Date.parse(new Date());
			var value2 = Date.parse(new Date(this.bidSectionData.value2))
			if(this.bidSectionData.value3 == 0 || this.bidSectionData.value3 == null || this.bidSectionData.value3 == '') {

				this.bidSectionData.value3 = parseInt((currentDate - value2) / (24 * 60 * 60 * 1000))+""
			}
			if(this.bidSectionData.value4 == 0 || this.bidSectionData.value4 == null || this.bidSectionData.value4 == '') {
				this.bidSectionData.value4 = parseInt((currentDate - value2) / (24 * 60 * 60 * 1000))+""
			}
			this.commonProgressVhartsParams.commencementDate = this.bidSectionData.value2
			this.commonProgressVhartsParams.constructionTime = this.bidSectionData.value3
			this.commonProgressVhartsParams.totalPlannedDuration = this.bidSectionData.value1
		})
	},
      // loadMaterialEcharts() {
      //   document.getElementsByClassName('material-echarts').forEach(function(e, i){
      //     var myChart = echarts.init(e)
			// 		myChart.setOption({
      //       title: {
      //         text: '材料'+(i+1),
      //         left: 'center',
      //         textStyle: {
      //         color: '#fff',
      //         fontSize: 16
      //       }
      //       },
      //       grid: {
      //         top: '20%',
      //           left: '3%',
      //           right: '4%',
      //           bottom: '10%',
      //           containLabel: true
      //       },
      //       xAxis: {
      //           type: 'category',
      //           data: ['总需求数量', '进场数量总值', '出场数量总值'],
      //           axisLabel: {
      //                 show: true,
      //                 rotate: 20,
      //                 margin: 20,
      //                 textStyle: {
      //                     fontSize: 12,
      //                     color: 'white'
      //                 }
      //             }
      //       },
      //       yAxis: {
      //           type: 'value',
      //           axisLabel: {
      //                 show: true,
      //                 margin: 20,
      //                 textStyle: {
      //                     fontSize: 12,
      //                     color: '#0F8CDC'
      //                 }
      //             },
      //             splitLine: {
      //               show:true,
      //             lineStyle: {
      //                 color: '#0D3253'
      //             }
      //             }
      //       },
      //       series: [{
      //           data: [120+(i*13), 200+(i*13), 150+(i*13)],
      //           type: 'bar',
      //           barWidth: 15,
      //           itemStyle: {
      //                 normal: {
      //                   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
      //                     offset: 0,
      //                     color: '#0F8CDC'
      //                   }, {
      //                     offset: 1,
      //                     color: 'rgba(15,140,220,0.1)'
      //                   }]),
      //                   borderRadius: 15
      //                 }
      //               },
      //           label: {
      //             show: true,
      //             fontSize: 12,
      //                   color: 'white',
      //                   position: 'top'
      //           }
      //       }]
			// 		})
      //   })
      // },
      async loadPersonnelShipCharts(){
        var datas = [
						[
                    {value: 15, name: '船员 15 人'},
                    {value: 20, name: '施工员 20 人'},
                    {value: 25, name: '民工 25 人'}
                ],
          [
                    {value: 5, name: '不在场 5 人'},
                    {value: 55, name: '在场 55 人'}
                ],
                [
                    {value: 15, name: '钻爆船 15 艘'},
                    {value: 20, name: '抓泥船 20 艘'}
                ],
          [
                    {value: 5, name: '不在场 5 艘'},
                    {value: 55, name: '在场 55 艘'}
                ]
					]
        await this.$get('personnel/getPersonnelNum').then(res => {
          var nums = []
          const { data } = res
          data.forEach(it => {
            nums.push({
              value: it.num,
              name: it.name + ' ' + it.num + ' 人'
            })
          })
          datas[0] = nums
        })
        await this.$get('personnel/getPersonnelStatus').then(res => {
          var nums = []
          const { data } = res
          nums.push({
            value: data.在场,
            name: '在场 '+data.在场 + ' 人'
          })
          nums.push({
            value: data.不在场,
            name: '不在场 '+data.不在场 + ' 人'
          })
          datas[1] = nums
        })
        var charts = []
        Array.from(document.getElementsByClassName('personnel-charts')).forEach(function(e){charts.push(e)})
        Array.from(document.getElementsByClassName('ship-charts')).forEach(function(e){charts.push(e)})
        charts.forEach(function(e, i){
					var getLegend = function(){
						var legends = []
						var y = 35
						for(var j in datas[i]){
							legends.push({
                  // orient: 'vertical',
                  x: '53%',
                  y: y+'%',
                  itemWidth: 20,
                  itemHeight: 10,
                  align: 'left',
                  textStyle: {
                      fontSize:12,
                      color: '#fff'
                  },
                  data:[datas[i][j].name]
              })
							y += 10;
						}
						return legends
					}
					var myChart = echarts.init(e)
					myChart.setOption({
            backgroundColor: 'transparent',
            legend: getLegend(),
            title: [{
                text: i == 0 ? '人员数量占比' : i == 1 ? '人员在场状态占比' : i == 2 ? '船舶类型占比' : '船舶状态占比',
                textAlign: 'center',
                textStyle: {
                    color: '#fff',
                    fontSize: 12,
                    fontWeight: 'normal'
                },
                left: '25%',
                top: '15%'
            },{
                text: '总'+(i<=1?'人':'艘')+'数',
                subtext: '{num|'+datas[i].map(it => it.value).reduce((a,b)=> a+b)+'}'+(i<=1?'人':'艘'),
                textAlign: 'center',
                x: '25%',
                y: '50%',
                textStyle: {
                    color: '#fff',
                    fontSize: 12,
                    fontWeight: 'normal',
                },
                subtextStyle: {
                    color: '#fff',
                    fontSize: 12,
                    rich: {
                        num: {
                            color: '#0EE88C',
                            fontSize: 16
                        }
                    }
                }
            }],
            tooltip: {
                trigger: 'item',
                backgroundColor: 'rgba(0,0,0,0.9)',
                formatter: function(params) {
                    return params.seriesName + '<br/>' + params.marker + '<span style="color:' + params.color + '">' + params.data['name'] + '\n' + params.data['value'] + '</span>';
                }
            },
            series: [

                {
                  top: '20%',
                    name: '',
                    type: 'pie',
                    hoverAnimation: false,
                    legendHoverLink: false,
                    radius: ['57%', '70%'],
                    center: ['27%', '50%'],
                    color: ['rgba(40,242,230,0.3)', 'rgba(2,224,93,0.3)', 'rgba(253,101,0,0.3)'],
                    label: {
                        normal: {
                          show: false,
                            position: 'inner'
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },

                    },
                    itemStyle: {
                        normal: {
                            // shadowBlur: 15,
                            // shadowColor: 'rgba(0, 0, 0, 0.5)',
                            borderColor:'#0a1a2a',
                            // borderWidth:'10',
                        }
                    },
                    tooltip: {
                        show: false,

                    },
                    data: datas[i].map(it => it.value)
                },
                {
                  top: '20%',
                    name: 'title',
                    type: 'pie',
                    radius: ['65%', '72%'],
                    center: ['27%', '50%'],
                    color: ['rgba(40,242,230, 1)', 'rgba(2,224,93,1)', 'rgba(253,101,0,1)'],
                    label: {
                        normal: {
                            show: false,
                            position: 'inside',
                            formatter: '{d}%',
                            textStyle: {
                                align: 'center',
                                baseline: 'middle',
                                fontSize: 16,
                                fontWeight: '100',
                            }
                        },
                    },
                    labelLine: {
                        normal: {
                            smooth: true,
                            length: 10,
                            lineStyle: {
                                width: 1.5
                            }
                        }
                    },
                    itemStyle: {
                        normal: {
                            shadowBlur: 15,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                            borderColor:'#0a1a2a',
                            // borderWidth:'5',
                        }
                    },
                    data: datas[i]
                }
            ]
					})
        })
      },
      loadAlarm() {
        this.$get('screen/alarm?current=' + this.alarmIndex).then(res => {
          this.alarmData = res.data.data
          this.alarmHasNext = res.data.hasMore;
          this.alarmTotal = Math.ceil(res.data.total/res.data.size)
          this.$refs.map.getAlarmList(this.alarmData)
          for(let d of this.alarmData) {
              let createDate = new Date(d.createDate)
              if(!d.processed && (new Date().getTime() - createDate.getTime()) < 180000 && !this.playsouding) {
                  this.playsouding = true;
              }
          }
        })
      },
      loadQualityCharts() {
        this.$get('/screen/quaility').then(res => {
          var data = res.data
          this.quailityData = data
          Array.from(document.getElementsByClassName('quaility-echarts')).forEach((e, i) => {
            var datas = [
              [
                {value: data.inspectNum, name: '检查次数'},
                {value: data.inspectQuestionNum, name: '发现问题'},
                {value: data.rectificationCompletedNum, name: '已整改 '}
              ],
              [
                {value: data.supervisionNum, name: '督查次数'},
                {value: data.supervisionQuestionNum, name: '发现问题'},
                {value: data.supervisionRectificationCompletedNum, name: '已整改'}
              ],
              [
                {value: data.unitEngineeringNum, name: '单位工程数量'},
                {value: data.unitAcceptanceNum, name: '已验收'},
                {value: data.unitUnqualifiedNum, name: '验收不合格'},
                {value: data.unitRectificationNum, name: '已整改'}
              ],
              [
                {value: data.concealmentEngineeringNum, name: '隐蔽工程数量'},
                {value: data.concealmentAcceptanceNum, name: '已验收'},
                {value: data.concealmentUnqualifiedNum, name: '验收不合格'},
                {value: data.concealmentRectificationNum, name: '已整改'}
              ],
              [
                {value: data.branchEngineeringNum, name: '分部工程数量'},
                {value: data.branchAcceptanceNum, name: '已验收'},
                {value: data.branchUnqualifiedNum, name: '验收不合格'},
                {value: data.branchRectificationNum, name: '已整改'}
              ],
              [
                {value: data.subItemEngineeringNum, name: '分项工程数量'},
                {value: data.subItemAcceptanceNum, name: '已验收'},
                {value: data.subItemUnqualifiedNum, name: '验收不合格'},
                {value: data.subItemRectificationNum, name: '已整改'}
              ]
            ]
            //e.previousSibling.getElementsByClassName('num')[0].innerHTML = datas[i][0].value
            datas[i].shift()
            var text = '';
            var subtext = '';
            var color1 = []
            var color2 = []
            if(i <= 1){
              text = (datas[i][1].value / datas[i][0].value * 100).toFixed(2) + '%'
              subtext = '已整改占比';
              color1 = ['rgba(22,71,94,0.3)', 'rgba(40,242,230,0.3)']
              color2 = ['rgba(22,71,94, 1)', 'rgba(40,242,230, 1)']
              datas[i][0].value = datas[i][0].value - datas[i][1].value
            }else{
              color1 = ['rgba(40,242,230,0.3)', 'rgba(253,101,0,0.3)', 'rgba(2,224,93,0.3)']
              color2 = ['rgba(40,242,230,1)', 'rgba(253,101,0,1)', 'rgba(2,224,93,1)']
            }
            var myChart = echarts.init(e)
            myChart.setOption({
              backgroundColor: 'transparent',
              title: [{
                  text: text,
                  subtext: subtext,
                  textAlign: 'center',
                  x: '47%',
                  y: '33%',
                  textStyle: {
                      color: '#fff',
                      fontSize: 12,
                      fontWeight: 'normal',
                  },
                  subtextStyle: {
                      color: '#fff',
                      fontSize: 12,
                  }
              }],
              tooltip: {
                  trigger: 'item',
                  backgroundColor: 'rgba(0,0,0,0.9)',
                  formatter: function(params) {
                      return params.seriesName + '<br/>' + params.marker + '<span style="color:' + params.color + '">' + params.data['name'] + '\n' + params.data['value'] + '</span>';
                  }
              },
              series: [

                  {
                      name: '',
                      type: 'pie',
                      hoverAnimation: false,
                      legendHoverLink: false,
                      radius: ['67%', '80%'],
                      // center: ['35%', '50%'],
                      color: color1,
                      label: {
                          normal: {
                            show: false,
                              position: 'inner'
                          }
                      },
                      labelLine: {
                          normal: {
                              show: false,
                          },

                      },
                      itemStyle: {
                          normal: {
                              // shadowBlur: 15,
                              // shadowColor: 'rgba(0, 0, 0, 0.5)',
                              borderColor:'#0a1a2a',
                              // borderWidth:'10',
                          }
                      },
                      tooltip: {
                          show: false,

                      },
                      data: datas[i].map(it => it.value)
                  },
                  {
                      name: 'title',
                      type: 'pie',
                      radius: ['75%', '82%'],
                      // center: ['35%', '50%'],
                      color: color2,
                      label: {
                          normal: {
                              show: false,
                              position: 'inside',
                              formatter: '{d}%',
                              textStyle: {
                                  align: 'center',
                                  baseline: 'middle',
                                  fontSize: 16,
                                  fontWeight: '100',
                              }
                          },
                      },
                      labelLine: {
                          normal: {
                              smooth: true,
                              length: 10,
                              lineStyle: {
                                  width: 1.5
                              }
                          }
                      },
                      itemStyle: {
                          normal: {
                              shadowBlur: 15,
                              shadowColor: 'rgba(0, 0, 0, 0.5)',
                              borderColor:'#0a1a2a',
                              // borderWidth:'5',
                          }
                      },
                      data: datas[i]
                  }
              ]
            })
          })
        })
      },
      loadSecurityEcharts() {
        this.$get('security/manage/statistics').then(res => {
          this.securityData = res.data[0]
          var value3 = Number(_this.bidSectionData.value3)
          var value1 = Number(_this.bidSectionData.value1)
          var questionNum = this.securityData.questionNum;
          var completeNum = this.securityData.completeNum;
          [...document.getElementsByClassName('security-echarts')].forEach(function(e, i){
            var datas = [
                [
                          {value: value3, name: '已施工\n'+(value3 / value1 * 100).toFixed(2)+'%'},
                          {value: value1, name: '总工期\n'+((value1 - value3) / value1 * 100).toFixed(2)+'%'},
                      ],
                [
                          {value: questionNum, name: '发现问题\n'+(questionNum / (questionNum + completeNum) * 100).toFixed(2)+'%'},
                          {value: completeNum, name: '已整改\n'+(completeNum / (questionNum + completeNum) * 100).toFixed(2)+'%'},
                      ]
              ]
              var myChart = echarts.init(e)
              myChart.setOption({
                  series: [
                      {
                        color: ['#0F8CDC', '#28F2E6'],
                          type: 'pie',
                          radius: '60%',
                          data: datas[i],
                          emphasis: {
                              itemStyle: {
                                  shadowBlur: 10,
                                  shadowOffsetX: 0,
                                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                              }
                          },
                          label: {
                              fontSize: 12,
                              color: '#fff'
                          },
                          labelLine: {
                            length: 3,
                            length2: 3
                          }
                      }
                  ]
              })
          })
        })
      },
      getShipsInfo(data) {
        //console.log(data);
        this.shipDatas = []
        var query = {}
        console.log(data);
        if (typeof (data.type) != 'undefined') {
          query['type'] = data.type
          this.shipTitle = data.typeName
          this.shipColumn = [
            {"prop":"name","label":"船舶名称"},
            {"prop":"buildDate","label":"建造日期","width":"90"},
            {"prop":"totalLen","label":"总长","width":"60"},
            {"prop":"shipLen","label":"船长","width":"60"},
            {"prop":"shapeDepth","label":"型深","width":"60"},
            {"prop":"profileWidth","label":"型宽","width":"60"},
            {"prop":"grossTonnage","label":"总吨位","width":"70"},
            {"prop":"netTonnage","label":"净吨位","width":"70"},
            {"prop":"noLoadDisplacement","label":"空载排水量","width":"90"},
            {"prop":"noLoadDraft","label":"空载吃水","width":"80"},
            {"prop":"fullLoadDisplacement","label":"满载排水量","width":"90"},
            {"prop":"fullDraft","label":"满载吃水","width":"80"},
            {"prop":"drillPipeModel","label":"钻杆型号","width":"80"},
            {"prop":"maximumPower","label":"最大功率","width":"80"},
            {"prop":"entryDate","label":"进场日期","width":"90"},
            {"prop":"departureDate","label":"离场日期","width":"90"},
            {"prop":"maximumPower","label":"最大功率","width":"80"},
            {"prop":"contacts","label":"联系人"},
            {"prop":"contactInfo","label":"联系方式"},
            {"prop":"longitude","label":"经度"},
            {"prop":"latitude","label":"纬度"}
          ]
          if(data.type == 2){
            this.shipColumn = [
              {"prop":"name","label":"船舶名称"},
              {"prop":"buildDate","label":"建造日期","width":"90"},
              {"prop":"totalLen","label":"总长","width":"60"},
              {"prop":"shipLen","label":"船长","width":"60"},
              {"prop":"shapeDepth","label":"型深","width":"60"},
              {"prop":"profileWidth","label":"型宽","width":"60"},
              {"prop":"grossTonnage","label":"总吨位","width":"70"},
              {"prop":"netTonnage","label":"净吨位","width":"70"},
              {"prop":"noLoadDisplacement","label":"空载排水量","width":"90"},
              {"prop":"noLoadDraft","label":"空载吃水","width":"80"},
              {"prop":"fullLoadDisplacement","label":"满载排水量","width":"90"},
              {"prop":"fullDraft","label":"满载吃水","width":"80"},
              {"prop":"grabCapacity","label":"抓斗容量","width":"80"},
              {"prop":"maximumPower","label":"最大功率","width":"80"},
              {"prop":"entryDate","label":"进场日期","width":"90"},
              {"prop":"departureDate","label":"离场日期","width":"90"},
              {"prop":"maximumPower","label":"最大功率","width":"80"},
              {"prop":"contacts","label":"联系人"},
              {"prop":"contactInfo","label":"联系方式"},
              {"prop":"longitude","label":"经度"},
              {"prop":"latitude","label":"纬度"}
            ]
          }else if(data.type == 3 || data.type == 6){
              this.shipColumn = [
                {"prop":"name","label":"船舶名称"},
                {"prop":"shipOwner","label":"船舶所有人"},
                {"prop":"nameOfCaptain","label":"船长姓名"},
                {"prop":"contactInfoOfCaptain","label":"船长通讯方式"},
                {"prop":"noLoadDraft","label":"空载吃水","width":"80"},
                {"prop":"fullDraft","label":"满载吃水","width":"80"},
                {"prop":"maximumPower","label":"最大功率","width":"80"},
                // {"prop":"shapeDepth","label":"船舶位置"},
                {"prop":"entryDate","label":"进场日期","width":"90"},
                {"prop":"departureDate","label":"离场日期","width":"90"},
                {"prop":"contacts","label":"联系人"},
                {"prop":"contactInfo","label":"联系方式"},
                 {"prop":"speed","label":"船舶速度"},
                {"prop":"longitude","label":"经度"},
                {"prop":"latitude","label":"纬度"}
              ]
          }
        } else {
          query['isPresent'] = data.isPresent
          this.shipTitle = data.statusName + '船舶'
          this.shipColumn = [
            {"prop":"name","label":"船舶名称"},
            {"prop":"buildDate","label":"建造日期"},
            {"prop":"totalLen","label":"总长","width":"60"},
            {"prop":"shipLen","label":"船长","width":"60"},
            {"prop":"shapeDepth","label":"型深","width":"60"},
            {"prop":"profileWidth","label":"型宽","width":"60"},
            {"prop":"grossTonnage","label":"总吨位","width":"70"},
            {"prop":"netTonnage","label":"净吨位","width":"70"},
            {"prop":"noLoadDisplacement","label":"空载排水量","width":"90"},
            {"prop":"noLoadDraft","label":"空载吃水","width":"80"},
            {"prop":"fullLoadDisplacement","label":"满载排水量","width":"90"},
            {"prop":"fullDraft","label":"满载吃水","width":"80"},
            {"prop":"drillPipeModel","label":"钻杆型号","width":"80"},
            {"prop":"maximumPower","label":"最大功率","width":"80"},
            {"prop":"entryDate","label":"进场日期"},
            {"prop":"departureDate","label":"离场日期"},
            {"prop":"maximumPower","label":"最大功率"},
            {"prop":"contacts","label":"联系人"},
            {"prop":"contactInfo","label":"联系方式"},
            {"prop":"longitude","label":"经度"},
            {"prop":"latitude","label":"纬度"}
          ]
        }
        this.$post('ship/getShipInfo', query).then(res => {
          this.shipDatas = res.data
          this.shipVisible = true
        })
      },
      preNextPage(index) {
        this.moreIndex = index
        this.$refs.moreComponent.setCharts(index)
      },
    }
  }
</script>

<style scoped>
  .clear{
    clear: both;
  }
  .content{
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 80px;
  }
  .content.single{
    top: 0;
  }
  .content.single .hidden-btn{
    display: none;
  }
  .content > .more{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 11;
    background: url(../../assets/img/common/bg.jpg) no-repeat;
    background-size: 100% calc(100% + 88px);
    background-position-y: -80px;
  }
  .content > .more .more-content {
    height: calc(100% - 30px)
   /*height:100%*/
  }
  .content > div{
    float: left;
  }
  .content .map-div {
    width: 50%;
    height: 100%;
  }
  .content > .left,
  .content > .right {
    width: 25%;
    height:  100%;
    flex: 1;
    display:flex;
    flex-flow: column;
  }
  .content .right > .item,
  .content .left > .item{
    flex: 1;
    margin: 0.5px 10px;
    box-sizing: border-box;
  }
  .content .right > .item > .title ,
  .content .left > .item > .title,
  .more .title {
    width: 100%;
    height: 30px;
    color: white;
    font-size: 14px;
    text-indent: 10px;
    line-height: 30px;
    border-bottom: 1px solid #044850;
    position: relative;
  }
  .more .title{
    padding-left: 10px;
    padding-right: 10px;
  }
  .content .right > .item > .title:before ,
  .content .left > .item > .title:before,
  .more .title:before {
    content: '';
    width: 20px;
    height: 2px;
    position: absolute;
    background: #31FFFF;
    top: 28px;
    left: 26px;
    border-radius: 5px;
  }
  .more .title:before {
    left: 36px;
  }
  .content .right > .item .item-content,
  .content .left > .item .item-content{
    height: calc(100% - 31px);
  }
  .content .right > .item .item-content{
    max-height: calc(100% - 31px);
  }
  .content .right > .item > .title a,
  .content .left > .item > .title a,
  .more .title a{
    float: right;
    text-decoration: none;
    color: #00ffff;
  }
  .security{
    font-size: 12px;
    color: #fff;
    height: 100%;
    padding-top: 15px;
    box-sizing: border-box;
  }
  .security .security-echarts{
    width: 190px;
    height: 80px;
    margin-left: -20px;
  }
  .security .left,
  .security .middle,
  .security .right{
    float: left;
    height: 100%;
  }
  .security .right{
    margin-left: 13px;
  }
  .security .row {
    margin: 2px 0px;
  }
  .security .row .num{
    color: #4BF3F9;
    width: 25px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    position: relative;
    top: 1px;
  }
  .security .row .date{
    color: #03C054;
    /*width: 100px;*/
    text-align: center;
    display: inline-block;
    font-size: 14px;
    position: relative;
    top: 1px;
  }
  .security .row span{
    width: 72px;
    text-align: justify;
    text-align-last: justify;
    display: inline-block;
  }
  .security .middle .row:nth-child(1) span{
    width: auto;
  }
  .security .education-training{
    width: 96px!important;
  }
  .security .right .row{
    margin: 2px 0px;
  }
  .quality .row .item{
    width: 33%;
    color: #fff;
    font-size: 12px;
    float: left;
    text-align: center;
  }
  .quality .row .item .block .legend{
    width: 10px;
    height: 10px;
    border-radius: 2px;
    display: inline-block;
    margin-right: 6px;
  }
  .quality .row .item .num {
    color: #4BF3F9;
    width: 30px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    position: relative;
    top: 1px;
  }
  .quality .row .item .quaility-echarts{
    height: 120px;
    width: 100%;
  }
  .progressCharts{
    width: 70%;
    height: 100%;
    float: left;
  }
  .progressTable{
    float:left;
    width: 30%;
    line-height: 60px;
    margin-top: 67px;
    font-size: 12px;
    color: white;
  }
  .progressTable td{
    line-height: 20px;
  }
  ul.alarm{
    list-style: none;
    color: #fff;
    font-size: 12px;
    max-height: 200px;
    overflow: auto;
  }
  ul.alarm li{
    background-image: url(../../assets/img/alarm-bg.png);
    height: 50px;
    line-height: 50px;
    padding-left: 10px;
    margin: 5px 0;
  }
  ul.alarm li label{
    display: inline-block;
    color: #01FFFF;
    padding-left: 65px;
    margin-right: 15px;
  }
  .ship-charts,
  .personnel-charts,
  .material-echarts{
    float: left;
    width: 50%;
    height: 100%;
  }
  .switch{
    float: right;
    margin-right: 10px;
    color: #27ECE1;
    font-size: 12px;
    text-indent: 0px;
    line-height: 15px;
    text-align: center;
    margin-top: 5px;
  }
  .switch .page {
		background: transparent;
    display: inline-block;
    line-height: 15px;
    margin: 0px 5px;
	}
  .switch .prev,
  .switch .next{
    border: 2px solid #27ECE1;
    background: transparent;
    width: 30px;
    display: inline-block;
    cursor: pointer;
    line-height: 15px;
    margin: 0px 5px;
  }
  #playerVideo #app {
    height: 580px;
  }
  #playerVideo >>> .el-dialog__headerbtn{
    top: 8px;
  }
  #playerVideo >>> .el-dialog__header{
    padding: 15px
  }
  #playerVideo >>> .el-dialog__body{
    padding: 0px 15px 15px 15px
  }
  .hidden-btn{
    position: fixed;
    width: 20px;
    height: 40px;
    color: #ffffff;
    top: calc(50% - 20px);
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    z-index: 1;
    background: rgb(0,0,128);
  }
  .shipDialog{
    padding: 0 10px 10px 10px;
  }

  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background: rgba(221, 221, 221, .3);
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(170, 170, 170, .4);
  }

  *::-webkit-scrollbar-thumb:hover {
    background: rgba(170, 170, 170, .7);
  }

  *::-webkit-scrollbar-corner {
    background: rgba(221, 221, 221, .3);
  }
  .alarm-table{
    color:#fff;
    border-spacing: 0;
    width: 100%;
    font-size: .7em;
    text-align: center;
    line-height: 24px;
    border-top: none;
  }
  .alarm-table td{
    border-bottom: 1px solid #044850;
  }
  .alarm-table td span{
    color: #00ffff;
    display: inline-block;
    padding: 0 2.5px;
    cursor: pointer;
  }

</style>
