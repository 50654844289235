import {Tile as TileLayer} from "ol/layer";
import {XYZ} from "ol/source";
import WMTSTileGrid from 'ol/tilegrid/WMTS';
const tileGrid = new WMTSTileGrid({
    tileSize : 256,
    origin: [-400.0, 400.0],
    resolutions: [
        0.023794610058302804,
        0.00951784402332112,
        0.00475892201166056,
        0.00237946100583028,
        0.00118973050291514,
        5.9486525145757E-4,
        2.97432625728785E-4,
        1.487163128643925E-4,
        7.435815643219625E-5,
        3.7179078216098126E-5,
        1.859072883855198E-5,
        9.294174688773075E-6,
        4.647087344386537E-6,
        2.37946100583028E-6
    ],
    extents : [103.37475604100007, 27.522702671000047, 121.75609231100009, 34.00673391200007]
    
})
export default new TileLayer({
    source : new XYZ({
        //url : 'http://81.71.123.19:8088/tiles/{z}/{x}/{y}.png',
        // url : 'http://127.0.0.1:8088/tiles/{z}/{x}/{y}.png',
        url: 'http://frp.shipzz.com:7233/tiles/{z}/{x}/{y}.png',
        tileGrid : tileGrid,
        projection : "EPSG:4326"
    })
}) 