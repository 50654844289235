import Draw from 'ol/interaction/Draw';
import Overlay from 'ol/Overlay';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {getArea, getLength} from 'ol/sphere';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {unByKey} from 'ol/Observable';
import {LineString, Polygon} from 'ol/geom';

let measureTooltipElement;
let helpTooltipElement;
let helpTooltip;
let measureTooltip;
let sketch;
let draw;
const source = new VectorSource()
const layer = new VectorLayer({
    source,
    style: new Style({
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
            color: '#ffcc33',
            width: 2,
        }),
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({
                color: '#ffcc33',
            }),
        }),
    })
})
/**
 * Message to show when the user is drawing a polygon.
 * @type {string}
 */
let continuePolygonMsg = '点击继续测量';

/**
 * Message to show when the user is drawing a line.
 * @type {string}
 */
let continueLineMsg = '点击继续测量,双击结束';

const formatLength = (line) => {
    return Math.round(getLength(line) * 100) / 100 + ' ' + 'm';
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
const formatArea = (polygon) => {
    let area = getArea(polygon);
    return Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
};
const pointerMoveHandler = (evt) => {
    if (evt.dragging) {
        return;
    }
    let helpMsg = '点击开始测量';
    if (sketch) {
        let geom = sketch.getGeometry();
        if (geom instanceof Polygon) {
            helpMsg = continuePolygonMsg;
        } else if (geom instanceof LineString) {
            helpMsg = continueLineMsg;
        }
    }

    helpTooltipElement.innerHTML = helpMsg;
    helpTooltip.setPosition(evt.coordinate);

    helpTooltipElement.classList.remove('hidden');
};
/**
 * Creates a new help tooltip
 */
const createHelpTooltip = (map) => {
    if (helpTooltipElement) {
        helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }
    helpTooltipElement = document.createElement('div');
    helpTooltipElement.className = 'ol-tooltip hidden ol-tooltip--measure';
    helpTooltip = new Overlay({
        element: helpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left',
    });
    map.addOverlay(helpTooltip);
}

/**
 * Creates a new measure tooltip
 */
const createMeasureTooltip = (map) => {
    if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    measureTooltipElement = document.createElement('div');
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure ol-tooltip--measure';
    measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
    });
    map.addOverlay(measureTooltip);
}
export function addToMap (map, type) {
    draw = new Draw({
        source,
        type: type || 'LineString',
        style: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: 'rgba(52, 120, 217, 0.8)',
                lineDash: [10, 10],
                width: 2,
            }),
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(52, 120, 217, 0.8)',
                }),
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
            }),
        }),
    });
    map.addInteraction(draw)
    map.addLayer(layer)
    createMeasureTooltip(map)
    createHelpTooltip(map)
    let listener;
    draw.on('drawstart', function (evt) {
        sketch = evt.feature;
        let tooltipCoord = evt.coordinate;
        listener = sketch.getGeometry().on('change', function (evt) {
            let geom = evt.target;
            let output;
            if (geom instanceof Polygon) {
                output = formatArea(geom);
                tooltipCoord = geom.getInteriorPoint().getCoordinates();
            } else if (geom instanceof LineString) {
                output = formatLength(geom);
                tooltipCoord = geom.getLastCoordinate();
            }
            measureTooltipElement.innerHTML = output;
            measureTooltip.setPosition(tooltipCoord);
        });
    });
    draw.on('drawend', function () {
        measureTooltipElement.className = 'ol-tooltip ol-tooltip-static ol-tooltip--measure';
        measureTooltip.setOffset([0, -7]);
        sketch = null;
        measureTooltipElement = null;
        createMeasureTooltip(map);
        unByKey(listener);
    });
    map.on('pointermove', pointerMoveHandler);
    map.getViewport().addEventListener('mouseout', function () {
        helpTooltipElement.classList.add('hidden');
    });

}
export function removeToMap(map) {
    map.removeInteraction(draw)
    source.clear()
    map.removeLayer(layer)
    map.removeOverlay(measureTooltip)
    map.removeOverlay(helpTooltip)
    document.querySelectorAll('.ol-tooltip--measure').forEach(it => {
        it.parentElement.removeChild(it)
    })
}
