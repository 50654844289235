<template>
	<div style="width: calc(100% - 10px)">
		<el-row :gutter="20">
			<el-col :span="8">
				<el-menu default-active="2" class="el-menu-vertical-demo">
					<el-menu-item index="2">
						<i class="el-icon-menu"></i>
						<span slot="title">导航二</span>
					</el-menu-item>
					<el-menu-item index="3" disabled>
						<i class="el-icon-document"></i>
						<span slot="title">导航三</span>
					</el-menu-item>
					<el-menu-item index="4">
						<i class="el-icon-setting"></i>
						<span slot="title">导航四</span>
					</el-menu-item>
				</el-menu>
			</el-col>
		</el-row>
	</div>
</template>

<style scoped>
	.el-menu{
		background-color:transparent;
		border-right:transparent;
	}
</style>

<script>
	export default {
		components: {},
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>

<style scoped>

</style>