<template>
	<div style="width: 100%;height: 100%">
		<div class="charts" v-for="(it, i) in chartsData" :key="i">
			<common-progress-charts v-bind="it" :is-radio="true" charts-style="height: 90%" />
		</div>
	</div>
</template>

<style scoped>
	.charts {
		width: 33%;
		height: 33%;
		float: left;
	}
</style>

<script>
	import CommonProgressCharts from '../charts/common-progress-charts'
	export default {
		components: {
			CommonProgressCharts
		},
		data() {
			return {
				chartsData: [],
				progressData: []
			}
		},
		created() {
			this.$get('progress/getChartsDataByName').then(res => {
				this.progressData = res.data;
				this.$emit('update:show-count',this.progressData.length)
				this.setCharts(1);
			})
		},
		mounted() {},
		methods: {
			setCharts(index) {
				this.chartsData = []
				for(var i = (index - 1) * 9; i <index * 9; i++) {
					if(i < this.progressData.length) {
						this.chartsData.push(this.progressData[i])
					} else {
						break;
					}
				}
			}
		}
	}
</script>

<style scoped>

</style>