<template>
	<div style="width: 100%;height: 100%">
		<div v-for="(item,i) of chartCount" :key="i" :style="chartsStyle" >
			<div style="width: 100%;height: 100%;" class="shipCharts"></div>
		</div>
		<!-- <div :style="chartsStyle" class="shipCharts"></div> -->
	</div>
</template>

<style scoped>

</style>

<script>
	import * as echarts from 'echarts';
	var _this
	export default {
		props: {
			chartsStyle: {
				type: String,
				default: 'padding:20px;width: 25%;height: 33%;float: left;'
			},
		},
		components: {},
		data() {
			return {
				chartCount: 12,
				shipList: [],
				num:1
			}
		},
		created() {
			_this = this
		},
		mounted() {
			this.loadShipCharts()
		},
		methods: {
			async loadShipCharts() {
				this.shipList = []
				const shipDailyCount = await this.$get('shipDaily/getShipDailyCount')
				const shipDailyWorkload = await this.$get('shipDaily/getShipDailyWorkload')
				for(let i = 0; i < shipDailyCount.data.length; i++) {
					this.shipList.push(shipDailyCount.data[i])
					this.shipList.push(shipDailyWorkload.data[i])
				}
				this.$emit('update:show-count',this.shipList.length)
				this.setCharts(1)
			},
			setCharts(index) {
				_this.num = index
				Array.from(document.getElementsByClassName('shipCharts')).forEach(function(e, i) {
					if(i >= _this.shipList.length-((index - 1) * 12)) {
						e.style.visibility="hidden";
						return false
					}
					e.style.visibility="visible";
					let xData = []
					let sData = []
					let ship = _this.shipList[i + ((index - 1) * 12)]
					let cTitle = ''
					if(i % 2 == 0) {
						xData = ['钻孔个数', '钻孔长度', '工程量', '工作时间']
						sData = [ship.drillHoleCount, ship.drillHoleLength, ship.quantitiesCount, ship.workingHoursCount]
						cTitle = '(工作量参数)'
					} else {
						xData = ['完成作业量', '考核作业量']
						sData = [ship.completeWorkloadCount, ship.assessmentWorkloadCount]
						cTitle = '(完成/考核指标量)'
					}
					var myChart = echarts.init(e)
					myChart.setOption({
						title: {
							text: ship.name + cTitle,
							left: 'center',
							textStyle: {
								color: '#fff',
								fontSize: 16
							}
						},
						grid: {
							top: '20%',
							left: '3%',
							right: '4%',
							bottom: '1%',
							containLabel: true
						},
						xAxis: {
							type: 'category',
							data: xData,
							axisLabel: {
								show: true,
								//rotate: 20,
								margin: 20,
								textStyle: {
									fontSize: 12,
									color: 'white'
								}
							}
						},
						yAxis: {
							type: 'value',
							axisLabel: {
								show: true,
								margin: 20,
								textStyle: {
									fontSize: 12,
									color: '#0F8CDC'
								}
							},
							splitLine: {
								show: true,
								lineStyle: {
									color: '#0D3253'
								}
							}
						},
						series: [{
							data: sData,
							type: 'bar',
							barWidth: 15,
							itemStyle: {
								normal: {
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#0F8CDC'
									}, {
										offset: 1,
										color: 'rgba(15,140,220,0.1)'
									}]),
									borderRadius: 15
								}
							},
							label: {
								show: true,
								fontSize: 12,
								color: 'white',
								position: 'top'
							}
						}]
					})
				})
			}
		}
	}
</script>

<style scoped>

</style>