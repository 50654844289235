<template>
  <div style="width: 100%;height: 100%">
    <common-material-charts :chartCount="9" charts-style="width: 33%;height: 30%;margin-top: 1%;float: left;"/>
  </div>
</template>

<style scoped>
.charts {
  width: 33%;
  height: 30%;
  float: left;
}
</style>

<script>
  import CommonMaterialCharts from '../charts/common-material-charts'
  export default {
    components: {
      CommonMaterialCharts
    },
    data() {
      return {
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
    }
  }
</script>

<style scoped>
  
</style>