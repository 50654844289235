import request from '@/utils/request'

export function getCustomAreas(){
    return request({
        url: '/customArea',
        method: 'get'
    })
}

export function saveArea(data) {
    return request({
        url: '/customArea/save',
        method: 'post',
        data
    })
}

export function removeArea(id) {
    return request({
        url: `/customArea/delete?id=${id}`,
        method: 'get'
    })
}