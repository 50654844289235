<template>
  <div class="charts-wrapper">
    <div :style="chartsStyle" id="shipCharts1"></div>
    <div :style="chartsStyle" id="shipCharts2"></div>
  </div>
</template>

<style scoped>
.charts-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>

<script>
import * as echarts from 'echarts';

var _this
export default {
  props: {
    chartsStyle: {
      type: String,
      default: 'width: 49%;height: 100%;float: left;'
    },
    chartCount: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
    }
  },
  created() {
    _this = this
  },
  mounted() {
    this.loadShipCharts()
  },
  methods: {
    async loadShipCharts() {
      await this.$get('ship/getShipTypeCount').then(res => {
        for (var i in res.data) {
          res.data[i]['value'] = res.data[i].count
          res.data[i]['name'] = res.data[i].typeName + " " + res.data[i].count + " 艘"
        }
        var datas = res.data
        this.setShipCharts(datas, '1')
      })
      await this.$get('ship/getShipStatusCount').then(res => {
        for (var i in res.data) {
          res.data[i]['value'] = res.data[i].count
          res.data[i]['name'] = res.data[i].statusName + " " + res.data[i].count + " 艘"
        }
        var datas = res.data
        this.setShipCharts(datas, '2')
      })
    },
    setShipCharts(datas, i) {
      var shipCharts = document.getElementById("shipCharts" + i)
      var getLegend = function () {
        var legends = []
        var y = 25
        for (var i in datas) {
          legends.push({
            // orient: 'vertical',
            x: '53%',
            y: y + '%',
            itemWidth: 20,
            itemHeight: 10,
            align: 'left',
            textStyle: {
              fontSize: 12,
              color: '#fff'
            },
            selectedMode:false,//禁用点击事件
            data: [datas[i].name]
          })
          y += 10;
        }
        return legends
      }

      var myChart = echarts.init(shipCharts)
       // 处理点击事件并且跳转到相应的百度搜索页面
      myChart.on('click', function (params) {
        var data = myChart.getOption()
        var shipData={}
        if(params.name != ""){
          shipData = params.data
        }else{
          shipData=data.series[1].data[params.dataIndex ]
        }
        console.log(shipData);
        _this.$emit('getShipsInfo',shipData)
      });
      myChart.setOption({
        backgroundColor: 'transparent',
        legend: getLegend(),
        title: [{
          text: i == 1 ? '船舶类型占比' : '船舶状态占比',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 12,
            fontWeight: 'normal'
          },
          left: '25%',
          top: '15%'
        }, {
          text: '总艘数',
          subtext: '{num|' + datas.map(it => it.value).reduce((a, b) => a + b) + '}艘',
          textAlign: 'center',
          x: '25%',
          y: '50%',
          textStyle: {
            color: '#fff',
            fontSize: 12,
            fontWeight: 'normal',
          },
          subtextStyle: {
            color: '#fff',
            fontSize: 12,
            rich: {
              num: {
                color: '#0EE88C',
                fontSize: 16
              }
            }
          }
        }],
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,0.9)',
          formatter: function (params) {
            return params.seriesName + '<br/>' + params.marker + '<span style="color:' + params.color + '">' + params.data['name'] + '\n' + params.data['value'] + '</span>';
          }
        },
        series: [

          {
            top: '20%',
            name: '',
            type: 'pie',
            hoverAnimation: false,
            legendHoverLink: false,
            radius: ['57%', '70%'],
            center: ['27%', '50%'],
            color: ['rgba(40,242,230,0.3)', 'rgba(2,224,93,0.3)', 'rgba(253,101,0,0.3)'],
            label: {
              normal: {
                show: false,
                position: 'inner'
              }
            },
            labelLine: {
              normal: {
                show: false,
              },

            },
            itemStyle: {
              normal: {
                // shadowBlur: 15,
                // shadowColor: 'rgba(0, 0, 0, 0.5)',
                borderColor: '#0a1a2a',
                // borderWidth:'10',
              }
            },
            tooltip: {
              show: false,

            },
            data: datas.map(it => it.value)
          },
          {
            top: '20%',
            name: 'title',
            type: 'pie',
            radius: ['65%', '72%'],
            center: ['27%', '50%'],
            color: ['rgba(40,242,230, 1)', 'rgba(2,224,93,1)', 'rgba(253,101,0,1)'],
            label: {
              normal: {
                show: false,
                position: 'inside',
                formatter: '{d}%',
                textStyle: {
                  align: 'center',
                  baseline: 'middle',
                  fontSize: 16,
                  fontWeight: '100',
                }
              },
            },
            labelLine: {
              normal: {
                smooth: true,
                length: 10,
                lineStyle: {
                  width: 1.5
                }
              }
            },
            itemStyle: {
              normal: {
                shadowBlur: 15,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                borderColor: '#0a1a2a',
                // borderWidth:'5',
              }
            },
            data: datas
          }
        ]
      })
    }
  }
}
</script>

<style scoped>
</style>