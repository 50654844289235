<template>
	<div class="main-container">
		<div class="map-container">

			<div class="ship-detail" v-if="dialogShipInfoVisible">
				<div class="header">
					<div class="title">
						{{ shipDetail.shipName || shipDetail.position.shipName || shipDetail.position.mmsi }}
						<span v-if="shipDetail.position.mmsi">
              ({{ shipDetail.position.mmsi }})
            </span>
					</div>
					<div class="close" @click="closeShipDetail()"></div>
					<div class="clear"></div>
				</div>
				<div class="content">
					<div class="ship-img" v-if="shipDetail.imgUrl">
						<h3>图片信息</h3>
						<img :src="shipDetail.imgUrl" />
					</div>
					<div class="ship-info" v-if="shipDetail.type === 'ship'">
						<el-tabs v-model="shipActive">
							<el-tab-pane label="船舶信息" class="active_class" name="shipInfo">
								<el-row :gutter="24">
									<el-col :span="11">
										船舶类型：<span>{{ shipDetail.position.shipTypeName }}</span>
									</el-col>
									<el-col :span="13">
										mmsi：<span>{{ shipDetail.position.mmsi || (shipDetail.position.mmsis&&shipDetail.position.mmsis[0]) }}</span>
									</el-col>
								</el-row>
								<el-row :gutter="24">
									<el-col :span="11">
										总长：<span>{{ shipDetail.position.shipLength || '-' }}</span>
									</el-col>
									<el-col :span="11">
										船长：<span>{{ shipDetail.position.shipLen || '-' }}</span>
									</el-col>
								</el-row>
								<el-row :gutter="24">
									<el-col :span="11">
										型深：<span>{{ shipDetail.position.shapeDepth || '-' }}</span>
									</el-col>
									<el-col :span="13">
										船宽：<span>{{ shipDetail.position.breadth || '-' }}</span>
									</el-col>
								</el-row>
								<el-row :gutter="24">
									<el-col :span="11">
										经度：<span>{{ shipDetail.hdms.x }}</span>
									</el-col>
									<el-col :span="13">
										纬度：<span>{{ shipDetail.hdms.y }}</span>
									</el-col>
								</el-row>
								<el-row :gutter="24">
									<el-col :span="11">
										航向：<span>{{ shipDetail.position.course}}</span>
									</el-col>
									<el-col :span="13">
										速度：<span>{{ shipDetail.position.speed}}</span>
									</el-col>
								</el-row>
								<el-row :gutter="24">
									<el-col :span="13">
										报位时间：<span>{{ shipDetail.postime }}</span>
									</el-col>
									<a style="color: blue;cursor: pointer" v-if="shipDetail.position.tenantId != null" @click="() => { moreShipInfo = !moreShipInfo; moreShipInfoBtn = moreShipInfo ? '收起↑' : '更多↓' }">{{ moreShipInfoBtn }}</a>
								</el-row>
								<div v-if="moreShipInfo">
									<el-row :gutter="24">
										<el-col :span="11">
											总吨位：<span>{{ shipDetail.position.grossTonnage }}</span>
										</el-col>
										<el-col :span="13">
											净吨位：<span>{{ shipDetail.position.netTonnage }}</span>
										</el-col>
									</el-row>
									<el-row :gutter="24">
										<el-col :span="11">
											空载排水量：<span>{{ shipDetail.position.noLoadDisplacement }}</span>
										</el-col>
										<el-col :span="13">
											空载吃水：<span>{{ shipDetail.position.noLoadDraft }}</span>
										</el-col>
									</el-row>
									<el-row :gutter="24">
										<el-col :span="11">
											满载排水量：<span>{{ shipDetail.position.fullLoadDisplacement }}</span>
										</el-col>
										<el-col :span="13">
											满载吃水：<span>{{ shipDetail.position.fullDraft }}</span>
										</el-col>
									</el-row>
									<el-row :gutter="24">
										<el-col :span="11">
											钻杆型号：<span>{{ shipDetail.position.drillPipeModel }}</span>
										</el-col>
										<el-col :span="13">
											最大功率：<span>{{ shipDetail.position.maximumPower }}</span>
										</el-col>
									</el-row>
								</div>
								<el-row :gutter="24" v-if="shipDetail.position.devices === undefined || shipDetail.position.devices.length === 1">
									<el-col :span="16">
										<el-date-picker v-model="historyTime" size="mini" type="datetimerange" range-separator="至" start-placeholder="开始日期" style="width: 300px;padding:3px 0;padding-left: 5px;" end-placeholder="结束日期">
										</el-date-picker>
									</el-col>
									<el-col :span="3" :offset="2">
										<el-button type="primary" size="mini" @click="queryHistory(shipDetail.position.shipId || shipDetail.position.mmsi, shipDetail.position.devices)">查询轨迹</el-button>
									</el-col>
								</el-row>
								<template v-else>
									<el-row :gutter="24">
										<el-col :span="24">
											<el-date-picker v-model="historyTime" size="mini" type="datetimerange" range-separator="至" style="width: 375px;padding:3px 0;padding-left: 5px;" start-placeholder="开始日期" end-placeholder="结束日期">
											</el-date-picker>
										</el-col>
									</el-row>
									<el-row>
										<el-col :span="16">
											<el-checkbox-group v-model="historyDevices">
												<el-checkbox v-for="(item, k) in shipDetail.position.devices" :key="k" :value="item" :label="item" :checked="item != '1'">{{ getDeviceType(item) }}</el-checkbox>
											</el-checkbox-group>
										</el-col>
										<el-col :span="3" :offset="2">
											<el-button type="primary" size="mini" @click="queryHistory(shipDetail.position.shipId || shipDetail.position.mmsi, historyDevices)">查询轨迹</el-button>
										</el-col>
									</el-row>
								</template>
                <el-button v-if="shipDetail.position.cameraId" type="primary" size="mini" @click="openShipCamera(shipDetail.position.cameraId, shipDetail.shipName || shipDetail.position.shipName || shipDetail.position.mmsi)">查看摄像头</el-button>
							</el-tab-pane>
							<el-tab-pane v-if="shipDetail.position.tenantId != null" class="ship-personnel" :label="'船员信息('+shipPersonnelData.length+'人)'" name="shipPersonnel">
								<div class="ship-personnel-date">
									{{inputDate}}
								</div>
								<el-table :data="shipPersonnelData" :header-cell-style="{'font-weight':'normal'}" size="mini" height="140">
									<el-table-column prop="name" label="姓名">
									</el-table-column>
									<el-table-column prop="sex" label="性别" width="40">
									</el-table-column>
									<el-table-column prop="duties" label="职务">
									</el-table-column>
									<el-table-column prop="inTime" label="进场时间" width="60">
									</el-table-column>
									<el-table-column prop="outTime" label="离场时间" width="60">
									</el-table-column>
								</el-table>
								<!--<div v-for="item in shipPersonnelData" :key="item.id">
									<el-row :gutter="24">
										<el-col :span="8">
											姓名：<span>{{ item.name }}</span>
										</el-col>
										<el-col :span="8">
											性别：<span>{{ item.sex }}</span>
										</el-col>
										<el-col :span="8">
											职务：<span>{{ item.duties }}</span>
										</el-col>
									</el-row>
									<el-row :gutter="24">
										<el-col :span="12">
											离场时间：<span>{{ item.outTime }}</span>
										</el-col>
										<el-col :span="12">
											进场时间：<span>{{ item.inTime }}</span>
										</el-col>
									</el-row>
								</div>-->
							</el-tab-pane>
							<el-tab-pane v-if="shipDetail.position.tenantId != null" class="ship-personnel" :label="'作业信息'" name="jobInformation">
								<el-table :data="jobInformationData" :header-cell-style="{'font-weight':'normal'}" size="mini" height="165">
									<el-table-column prop="inputDate" label="时间">
									</el-table-column>
									<el-table-column prop="site" label="施工地点" width="60">
									</el-table-column>
									<el-table-column prop="personnelNum" label="人数" width="40">
									</el-table-column>
									<el-table-column prop="dailyQuantities" label="日工作量" width="60">
									</el-table-column>
									<el-table-column prop="cumulativeQuantities" label="累计工作量" width="70">
									</el-table-column>
									<el-table-column prop="inputPersonnel" label="填报人" width="60">
									</el-table-column>
								</el-table>
							</el-tab-pane>
							<el-tab-pane v-if="shipDetail.position.tenantId != null" label="附属信息" class="active_class2" name="ancillaryInformation">
								<el-row type="flex">
									<el-col :span="11">
										所属单位：<span>{{ shipDetail.position.affiliatedUnit }}</span>
									</el-col>
									<el-col :span="13">
										<el-carousel height="130px">
											<div v-if="shipDetail.imgUrls.length > 0">
												<el-carousel-item v-for="item in shipDetail.imgUrls" :key="item" style = "text-align: center;">
													<el-image style="height: 120px" :src="'https://chaofu-oss.oss-cn-beijing.aliyuncs.com/'+item" fit="fill"></el-image>
												</el-carousel-item>
											</div>
											<div v-else>
												<el-carousel-item v-for="item in 1" :key="item" style="text-align: center;">
													<el-image>
														<div slot="error" class="image-slot">
															<i class="el-icon-picture-outline"></i>
														</div>
													</el-image>
												</el-carousel-item>
											</div>
										</el-carousel>
									</el-col>
								</el-row>
								<el-row type="flex" style="margin-top: -115px;">
									<el-col :span="11">
										建造时间：<span>{{ shipDetail.position.buildDate }}</span>
									</el-col>
								</el-row>
								<el-row type="flex">
									<el-col :span="11">
										进场时间：<span>{{ shipDetail.position.entryDate }}</span>
									</el-col>
								</el-row>
								<el-row type="flex">
									<el-col :span="11">
										离场时间：<span>{{ shipDetail.position.departureDate }}</span>
									</el-col>
								</el-row>
								<el-row type="flex">
									<el-col :span="11">
										负责人：<span>{{ shipDetail.position.leadingCadre  }}</span>
									</el-col>
								</el-row>
								<el-row type="flex">
									<el-col :span="11">
										通讯方式：<span>{{ shipDetail.position.contactInfo  }}</span>
									</el-col>
								</el-row>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>

			<div class="ship-detail" v-if="dialogCarInfoVisible">
				<div class="header">
					<div class="title">
						{{ carDetail.carName}}
						<span v-if="carDetail.carNum">
              ({{ carDetail.carNum }})
            </span>
					</div>
					<div class="close" @click="closeCarDetail()"></div>
					<div class="clear"></div>
				</div>
				<div class="content">
					<div class="ship-info" >
						<el-tabs v-model="carActive">
							<el-tab-pane label="车辆信息" class="active_class" name="carInfo">
								<el-row :gutter="24">
									<el-col :span="11">
										车辆类型：<span>{{ carDetail.carTypeName }}</span>
									</el-col>
									<el-col :span="13">
										车牌号：<span>{{ carDetail.carNum }}</span>
									</el-col>
								</el-row>
								<el-row :gutter="24">
									<el-col :span="11">
										品牌：<span>{{ carDetail.brand }}</span>
									</el-col>
									<el-col :span="13">
										型号：<span>{{ carDetail.model }}</span>
									</el-col>
								</el-row>
								<el-row :gutter="24">
									<el-col :span="11">
										经度：<span>{{ carDetail.hdms.x }}</span>
									</el-col>
									<el-col :span="13">
										纬度：<span>{{ carDetail.hdms.y }}</span>
									</el-col>
								</el-row>
								<el-row :gutter="24">
									<el-col :span="11">
										航向：<span>{{ carDetail.course}}</span>
									</el-col>
									<el-col :span="13">
										速度：<span>{{ carDetail.speed}}</span>
									</el-col>
								</el-row>
								<el-row :gutter="24">
									<el-col :span="24">
										报位时间：<span>{{ carDetail.postimeStr }}</span>
									</el-col>
								</el-row>
								<el-row :gutter="24">
									<el-col :span="16">
										<el-date-picker v-model="historyTime" size="mini" type="datetimerange" range-separator="至" start-placeholder="开始日期" style="width: 300px;padding:3px 0;padding-left: 5px;" end-placeholder="结束日期">
										</el-date-picker>
									</el-col>
									<el-col :span="3" :offset="2">
										<el-button type="primary" size="mini" @click="queryHistory(carDetail.id, [])">查询轨迹</el-button>
									</el-col>
								</el-row>
							</el-tab-pane>
							<el-tab-pane label="附属信息" class="active_class" name="ancillaryInformation">
								<el-row type="flex">
									<el-col :span="11">
										所属单位：<span>{{ carDetail.affiliatedUnit }}</span>
									</el-col>
									<el-col :span="13">
										<el-carousel height="130px">
											<div v-if="carDetail.imgUrls.length > 0">
												<el-carousel-item v-for="item in carDetail.imgUrls" :key="item" style = "text-align: center;">
													<el-image style="height: 120px" :src="'https://chaofu-oss.oss-cn-beijing.aliyuncs.com/'+item" fit="fill"></el-image>
												</el-carousel-item>
											</div>
											<div v-else>
												<el-carousel-item v-for="item in 1" :key="item" style="text-align: center;">
													<el-image style="height: 120px">
														<div slot="error" class="image-slot">
															<i class="el-icon-picture-outline"></i>
														</div>
													</el-image>
												</el-carousel-item>
											</div>
										</el-carousel>
									</el-col>
								</el-row>
								<el-row type="flex" style="margin-top: -115px;">
									<el-col :span="11">
										用途描述：<span>{{ carDetail.purposeDescription }}</span>
									</el-col>
								</el-row>
								<el-row type="flex">
									<el-col :span="11">
										联系人：<span>{{ carDetail.contacts }}</span>
									</el-col>
								</el-row>
								<el-row type="flex">
									<el-col :span="11">
										联系方式：<span>{{ carDetail.contactInfo }}</span>
									</el-col>
								</el-row>
								<el-row type="flex">
									<el-col :span="11">
										负责人：<span>{{ carDetail.leadingCadre  }}</span>
									</el-col>
								</el-row>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>

			<div class="beach-risk-detail" v-if="dialogBeachRiskInfoVisible">
				<div class="header">
					<div class="title">
						{{ beachRiskDetail.name}}
					</div>
					<div class="close" @click="closeBeachRiskDetail()"></div>
					<div class="clear"></div>
				</div>
				<div class="content">
					<div class="ship-info">
						<el-tabs v-model="beackRiskActive">
							<el-tab-pane label="图片信息" class="active_class" name="beackRiskImg">
								<el-row :gutter="24">
									<!-- <el-carousel height="210px">
										<el-carousel-item v-for="item in shipDetail.imgUrl" :key="item">
											<el-image style="width: 180px; height: 200px" :src="'https://chaofu-oss.oss-cn-beijing.aliyuncs.com/'+item" fit="fill"></el-image>
										</el-carousel-item>
									</el-carousel> -->
									<img :src="'https://chaofu-oss.oss-cn-beijing.aliyuncs.com/'+beachRiskDetail.url" />
								</el-row>
							</el-tab-pane>
							<el-tab-pane label="简介" class="active_class" name="beackRiskIntroduce">
								<el-row style="font-size: 12px;">
									<el-col :span="2">工程简介：</el-col><el-col :span="21"><span v-html="beachRiskDetail.content"></span></el-col>
								</el-row>
								<br>
								<el-row v-for="item in progressIntroduceDetail" :key="item.id" style="font-size: 12px;">
										<el-col :span="2">施工项目：</el-col><el-col :span="6"><span v-html="item.constructionProject"></span></el-col>
										<el-col :span="2">施工状态：</el-col><el-col :span="5"><span v-html="item.progressStatus"></span></el-col>
										<el-col :span="3">已施工(天)：</el-col><el-col :span="6"><span v-html="item.constructionTime"></span></el-col>
									<br>
								</el-row>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>

			<div class="beach-risk-detail" v-if="dialogWaterLevelInfoVisible">
				<div class="header">
					<div class="title">
						水位站：{{ waterLevelDetail.waterSite}}
					</div>
					<div class="close" @click="closeWaterLevelDetail()"></div>
					<div class="clear"></div>
				</div>
				<div class="content" style="text-align: center;">
					<el-date-picker v-model="waterLevelInputDate" size="small" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="getWaterSiteData(waterLevelDetail)"/>
					<div style="width: 800px;height: 450px;" id="waterSiteChart">
					</div>
				</div>
			</div>

			<div class="ship-detail" v-if="dialogAlarmInfoVisible">
				<div class="header">
					<div class="title">
						{{ alarmDetail.name}}
					</div>
					<div class="close" @click="closeAlarmDetail()"></div>
					<div class="clear"></div>
				</div>
				<div class="content">
					<div class="ship-info">
						<el-row :gutter="24">
							<el-col :span="11">
								船舶名称：<span>{{ alarmDetail.shipName }}</span>
							</el-col>
							<el-col :span="13">
								mmsi：<span>{{ alarmDetail.mmsi }}</span>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="11">
								报警时间：<span>{{ alarmDetail.alarmTime }}</span>
							</el-col>
							<el-col :span="13">
								状态：<span>{{ alarmDetail.navStatus }}</span>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="11">
								速度：<span>{{ alarmDetail.speed }}</span>
							</el-col>
							<el-col :span="13">
								航向：<span>{{ alarmDetail.course }}</span>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="11">
								经度：<span>{{ alarmDetail.lat }}</span>
							</el-col>
							<el-col :span="13">
								纬度：<span>{{ alarmDetail.lon }}</span>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>

			<div class="ship-search">
				<el-select style="width: 400px;" v-model="searchOption.value" filterable remote clearable reserve-keyword placeholder="请输入船名、MMSI、摄像头名称" :remote-method="remoteMethod" @change="shipSearchSelect" :loading="searchOption.loading">
					<el-option-group v-for="group in searchOption.options" :key="group.label" :label="group.label">
						<el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-option-group>
				</el-select>
			</div>

			<div ref="map" class="map">
				<div class="mouse-position" id="mouse-position" v-html="defaultMousePosition"></div>
				<div class="scaleLine" id="scaleLine"></div>
			</div>

			<div class="toolbar-container unselectable" :style="informationBarStyle">
				<div class="toolbar-item tool-expand" title="展开通告" v-if="!informationShow" @click="informationToggle">
					<i class="iconfont">&#xe736;</i>
					<span>通告</span>
				</div>
				<div class="toolbar-item tool-collapse" title="隐藏通告" v-if="informationShow" @click="informationToggle">
					<i class="iconfont">&#xe737;</i>
					<span>通告</span>
				</div>
				<div class="toolbar-item" title="航行航道通告" @click="getNoticeList('航行航道通告')">
					<i class="iconfont">&#xe63d;</i>
					<span>航行航道通告</span>
				</div>
				<div class="toolbar-item" title="航道维护尺度" @click="getNoticeList('航道维护尺度')">
					<i class="iconfont">&#xe64a;</i>
					<span>航道维护尺度</span>
				</div>
				<div class="toolbar-item" title="施工船舶" @click="getTableList('施工船舶')">
					<i class="iconfont">&#xe66e;</i>
					<span>施工船舶</span>
				</div>
				<div class="toolbar-item" title="应急管理" @click="getNoticeList('应急管理')">
					<i class="iconfont">&#xe683;</i>
					<span>应急管理</span>
				</div>
				<div class="toolbar-item" title="安全信息预警" @click="getNoticeList('安全信息预警')">
					<i class="iconfont">&#xe60d;</i>
					<span>安全信息预警</span>
				</div>
			</div>


			<div class="toolbar-container unselectable" :style="'top:80px;'+toolBarStyle">
				<div class="toolbar-item tool-expand" title="展开标记" v-if="!toolShow" @click="toolToggle">
					<i class="iconfont">&#xe736;</i>
					<span>标记</span>
				</div>
				<div class="toolbar-item tool-collapse" title="隐藏标记" v-if="toolShow" @click="toolToggle">
					<i class="iconfont">&#xe737;</i>
					<span>标记</span>
				</div>
				<div v-for="(item,index) in draws" :key="'draw_' + index" class="toolbar-item" :title="'画' + item.name" @click="changeDraw(item.type)">
					<i class="iconfont" :class="'icon-' + item.icon"></i>
					<span>{{ item.name }}</span>
				</div>
				<div class="toolbar-item" :style="{color: selectedFeature ? '#2770D7' : ''}" title="删除" @click="doRemove">
					<i class="iconfont icon-delete"></i>
					<span>删除</span>
				</div>
				<div class="toolbar-item" title="过滤" @click="filterShow = true">
					<i class="iconfont icon-2"></i>
					<span>过滤</span>
				</div>
				<div class="toolbar-item" title="原点" @click="resetCenter">
					<i class="iconfont">&#xe633;</i>
					<span>原点</span>
				</div>
				<div class="toolbar-item" :class="{'selected': measureing}" title="测距" @click="doMeasure">
					<i class="iconfont">&#xe604;</i>
					<span>测距</span>
				</div>
			</div>
			<div class="toolbar-container toolbar-vertical">
				<div class="toolbar-item" @click="toolbarHidden = !toolbarHidden">
					<i class="iconfont icon-shousuo" :style="{ transform: toolbarHidden ? 'rotate(270deg)' : 'rotate(90deg)' }"></i>
					<span>{{ toolbarHidden ? '显示' : '隐藏' }}</span>
				</div>
				<template v-if="!toolbarHidden">
					<div v-for="(item,index) in toolbox" :key="'toolbox_' + index" :class="{'selected' : item.selected}" class="toolbar-item" :title="'显示' + item.name" @click="()=>item.layer.setVisible(item.selected = !item.selected)">
						<i class="iconfont" :class="'icon-' + item.icon"></i>
						<span>{{ item.name }}</span>
					</div>
				</template>
			</div>
		</div>
		<el-dialog width="200px" :visible.sync="filterShow" title="船舶类型过滤" :close-on-click-modal="false" :destroy-on-close="true" :modal="false" v-dialogDrag>
			<el-form>
				<el-form-item v-for="(key,value) in shipTypeFilter" :key="value" :label="value" label-width="40" align="right">
					<el-switch v-model="shipTypeFilter[value]" @change="shipSource.changed()"></el-switch>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog width="320px" title="设置样式" :visible.sync="featureStyleDialog" :close-on-click-modal="false" :destroy-on-close="true" :show-close="false" :modal="false" v-dialogDrag>
			<el-form :model="featureStyle" size="mini" :inline="true">
				<el-form-item label="标注名称" label-width="100px">
					<el-input v-model="featureStyle.name" />
				</el-form-item>
				<el-form-item label="文字颜色" label-width="100px">
					<el-color-picker v-model="featureStyle.textStyle" color-format="rgb" size="mini"></el-color-picker>
				</el-form-item>
				<el-form-item label="文字边框颜色" label-width="100px">
					<el-color-picker v-model="featureStyle.textStrokeStyle" color-format="rgb" size="mini"></el-color-picker>
				</el-form-item>
				<el-form-item label="边框颜色" label-width="100px">
					<el-color-picker v-model="featureStyle.strokeStyle" show-alpha color-format="rgb" size="mini"></el-color-picker>
				</el-form-item>
				<el-form-item label="填充颜色" label-width="100px">
					<el-color-picker v-model="featureStyle.fillStyle" show-alpha color-format="rgb" size="mini"></el-color-picker>
				</el-form-item>
				<el-form-item label="上传图片" prop="icon" v-if="selectedDrawType === 'Point'">
					<pd-upload-image :url.sync="featureStyle.icon" :detail.sync="featureStyle.iconDetail" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="onSaveArea(false)">取 消</el-button>
				<el-button type="primary" @click="onSaveArea(true)">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog width="400px" top="10%" :visible.sync="noticeShow" :title="noticeTitle" :close-on-click-modal="false" :destroy-on-close="true" :modal="false" v-dialogDrag>
			<div style="padding-top: 10px;" v-for="(item,index) in noticeList" :key="item.id" @click="item.title !='应急处置' ? pdfPreview(item) : getTableList('应急处置')">
				<el-row :gutter="24" class="selectClass">
					<el-col :span="16">
						{{ index+1 }}、{{ item.title }}
					</el-col>
					<el-col :span="8">
						{{ item.noticsDate }}
					</el-col>
				</el-row>
			</div>
		</el-dialog>

		<el-dialog width="1000px" top="10%" :visible.sync="tableShow" :title="tableTitle" :close-on-click-modal="false" :destroy-on-close="true" :modal="false" v-dialogDrag>
			<el-table ref="table" max-height="500px" :data="tableData" @cell-click="tableCellClick">
				<el-table-column v-for="item in columnData" :key="item.prop" :prop="item.prop ? item.prop :''" :label="item.label ? item.label :''" show-overflow-tooltip/>
			</el-table>
		</el-dialog>

		<el-dialog width="1000px" top="10%" class="pdfClass" :visible.sync="pdfShow" :title="pdfTitle+'预览'" :close-on-click-modal="false" :destroy-on-close="true" :modal="false" v-dialogDrag>
			<iframe class="iframe-div" :src="'/pdfjs/web/viewer.html?file='+pdfUrl" width="100%" height="600" frameborder="no" border="0"></iframe>
		</el-dialog>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import '@/assets/iconfont/iconfont.css'
	import 'ol/ol.css';
	import Map from 'ol/Map';
	import View from 'ol/View';
	import { fromLonLat, transformExtent, getPointResolution, get as getProj } from 'ol/proj';
	import Feature from 'ol/Feature';
	import { toStringHDMS } from 'ol/coordinate';
	import { MousePosition, ScaleLine } from 'ol/control';
	import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style'
	import { Group as GroupLayer, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
	import { getVectorContext } from 'ol/render'
	import { easeOut } from 'ol/easing';
	import { Vector as VectorSource, XYZ } from 'ol/source';
	import { Circle, LineString, Point, Polygon, GeometryCollection } from 'ol/geom';
	import Draw from 'ol/interaction/Draw';
	import { getCustomAreas, removeArea, saveArea } from '@/api/area.js'
  import { addToMap, removeToMap } from './measure-layer'
	import {
		anchorList,
		getAisPositions,
		getShipAllPersonnelStatus,
		getCameraList,
		// getDevicePos,
		// getShipInfo,
		// getShipPos,
		getCustomShipList,
		getCustomCarList,
		sailChannelNoticsList,
		maintenanceScaleList,
		safetyWarningList,
		shipList,
		contingencyManagementList,
		emergencyMisposalList,
		markerList,
		getAisHistory,
		enclosureList,
		filePreview,
		getBeachRiskList,
		getWaterLevelList,
		getWaterSiteList,
		getShipDailyList,
		getProgressIntroduceData
	} from "@/api/map.js";
	import ChannelLayer from './channel-layer.js';
	import DxfLayer from './dxf-layer.js';
	import bus from '@/utils/bus';
	// eslint-disable-next-line no-unused-vars
	var _this
	export default {
		name: 'Map',
		props: {
			singleClick: Function,
			layerMenu: {
				type: Array,
				default: () => []
			}
		},
		data() {
			let drawSource = new VectorSource({
				wrapX: false
			})
			let drawVector = new VectorLayer({
				source: drawSource
			})
			let areaSource = new VectorSource({
				wrapX: false
			})
			let beachRiskSource = new VectorSource()
			let waterLevelSource = new VectorSource()
			let alarmSource = new VectorSource()
			let carSource = new VectorSource()
			let areaVector = new VectorLayer({
				source: areaSource,
				zIndex: 2,
				style: (feature) => {
					let data = feature.get('data')
					var styles = []
					if(data.icon) {
						var icon = new Icon({
							src: data.icon,
							scale: 1
						})
						if(data.selected) {
							icon = new Icon({
								src: data.icon,
								scale: 1,
								color: '#00FFFF'
							})
						}
						styles.push(new Style({
							text: new Text({
								text: data.name,
								font: '14px sans-serif',
								fill: new Fill({
									color: data.textStyle
								}),
								offsetY: -(data.iconHeight / 2 + 15),
								stroke: new Stroke({
									color: data.textStrokeStyle,
									width: 5
								})
							}),
							image: icon
						}))
					} else {
						styles.push(new Style({
							stroke: new Stroke({
								color: data.strokeStyle
							}),
							fill: new Fill({
								color: data.fillStyle
							}),
							text: new Text({
								text: data.name,
								font: '14px sans-serif',
								fill: new Fill({
									color: data.textStyle
								}),
								offsetY: -15,
								stroke: new Stroke({
									color: data.textStrokeStyle,
									width: 5
								})
							}),
							image: new CircleStyle({
								fill: new Fill({
									color: data.fillStyle
								}),
								stroke: new Stroke({
									color: data.strokeStyle
								}),
								radius: 5
							})
						}))
					}
					console.log("area")
					if(data.selected) {
						styles.push(new Style({
							stroke: new Stroke({
								color: '#00FFFF',
								width: 5
							}),
							image: new CircleStyle({
								stroke: new Stroke({
									color: '#00FFFF'
								}),
								radius: 6
							})
						}))
						if(data.areaType == 'Point'){
							styles.push(new Style({
								fill: new Fill({
									color: '#00FFFF'
								}),
								image: new CircleStyle({
									fill: new Fill({
										color: '#00FFFF'
									}),
									stroke: new Stroke({
										color: '#00FFFF'
									}),
									radius: 5
								})
							}))
						}
					}
					return styles
				}
			})

			let beachRiskVector = new VectorLayer({
				source: beachRiskSource,
				style: (feature) => {
					return new Style({
						image: new Icon({
							src: require('@/assets/images/collection.png'),
							scale: 0.8
						}),
						text: new Text({
							text: feature.get('data').name,
							textBaseline: 'top',
							offsetX: 30,
							stroke: new Stroke({
								color: '#fff',
								width: 5
							}),
							fill: new Fill({
								color: 'rgb(0,72,255,1)'
							})
						}),
						zIndex: 0
					})
				}
			})

			let waterLevelVector = new VectorLayer({
				zIndex: 2,
				source: waterLevelSource,
				style: (feature) => {
					return new Style({
						image: new Icon({
							src: require('@/assets/images/waterSite.png'),
							scale: 0.8
						}),
						text: new Text({
							text: feature.get('data').waterSite,
							textBaseline: 'top',
							offsetX: 30,
							stroke: new Stroke({
								color: '#fff',
								width: 5
							}),
							fill: new Fill({
								color: 'rgb(0,72,255,1)'
							})
						})
					})
				}
			})

			let alarmVector = new VectorLayer({
				source: alarmSource,
				zIndex: 1,
				style: (feature) => {
					/*return new Style({
						image: new Icon({
							src: require('@/assets/images/alarm.png'),
							scale: 0.8
						}),
						text: new Text({
							text: feature.get('data').shipName || feature.get('data').mmsi,
							textBaseline: 'top',
							offsetX: 30,
							stroke: new Stroke({
								color: '#fff',
								width: 5
							}),
							fill: new Fill({
								color: 'rgb(255 0 0)'
							})
						}),
						zIndex: 0
					})*/
					var data = feature.get('data')
					if(data.selected || data.processed !== '1') {
						var styles = [new Style({
							image: new CircleStyle({
								radius: 7,
								/*fill: new Fill({
									color: 'red'
								}),*/
								stroke: new Stroke({
									color: 'red'
								})
							}),
							text: new Text({
								text: (feature.get('data').shipName || feature.get('data').mmsi),
								textBaseline: 'top',
								offsetX: 30,
								stroke: new Stroke({
									color: '#fff',
									width: 5
								}),
								fill: new Fill({
									color: 'rgb(255 0 0)'
								})
							}),
						})]
						if(feature.get('data').id == this.selectAlarmFeatureValue) {
							styles.push(new Style({
								image: new Icon({
									src: require('@/assets/images/map/select.png'),
									scale: 0.8
								})
							}))
						}
						return styles;
					}
				}
			})

			let carVector = new VectorLayer({
				source: carSource,
				style: (feature) => {
					var data = feature.get('data')
					var styles = [new Style({
						image: new Icon({
							src: require('@/assets/images/car.png'),
							scale: 1.2
						}),
						zIndex: 0
					}),
					new Style({
							text: new Text({
								offsetX: 22,
								offsetY: -20,
								text: data.carName || data.carNum,
								textAlign: 'left',
								backgroundFill: new Fill({
									color: [255, 255, 255, .8]
								}),
								backgroundStroke: new Stroke({
									color: [213, 228, 252, 1],
									width: 1
								}),
								padding: [4, 3, 2, 22],
								font: '12px sans-serif'
							}),
							zIndex: 4
						}),
						new Style({
							text: new Text({
								offsetX: 12,
								offsetY: -21,
								text: '车',
								textAlign: 'center',
								fill: new Fill({
									color: 'white'
								})
							}),
							image: new CircleStyle({
								radius: 7,
								fill: new Fill({
									color: 'blue'
								}),
								displacement: [12, 21]
							}),
							zIndex: 5
						})
					]
					if(data.selected) {
						styles.push(new Style({
							image: new Icon({
								src: require('@/assets/images/map/select.png'),
								scale: 0.8
							}),
							zIndex: 2
						}))
					}
					return styles
				}
			})

			let markerSource = new VectorSource();
			let markerLayer = new VectorLayer({
				source: markerSource,
				declutter: true,
				maxResolution: 300 / 2 / 2 / 2 / 2 / 2
			})
			let anchorSource = new VectorSource();
			let anchorLayer = new VectorLayer({
				source: anchorSource
			})
			let trackSource = new VectorSource();
			let trackLayer = new VectorLayer({
				source: trackSource,
				declutter: true,
				style: feature => {
					let geometry = feature.getGeometry()
					if(geometry instanceof LineString) {
						return new Style({
							stroke: new Stroke({
								color: [0, 0, 255],
								width: 2
							})
						})
					} else {
						return new Style({
							image: new CircleStyle({
								radius: 3,
								fill: new Fill({
									color: [0, 0, 255]
								})
							}),
							text: new Text({
								offsetX: -5,
								text: feature.get('text'),
								textAlign: 'right',
								backgroundFill: new Fill({
									color: [201, 220, 252, .8]
								}),
								backgroundStroke: new Stroke({
									color: [0, 0, 0, 1],
									width: 1
								}),
								padding: [4, 3, 2, 6],
								font: '12px sans-serif'
							})
						})
					}
				}
			})
			let shipSource = new VectorSource();
			let shipLayer = new VectorLayer({
				zIndex: 2,
				source: shipSource,
				style: (feature, res) => {
					var ship = feature.get('data')
					if(!this.shipTypeFilter[ship.shipTypeName] && !ship.selected) {
						return;
					}
					var text = '' + (ship.shipNameCh || ship.shipNameEn || ship.shipName || ship.mmsi || ship.TRUCK_NUM);
					return this.getShipStyle(feature.getGeometry().getCoordinates(), ship.breadth, ship.shipLength, ship.speed, ship.heading, ship.course, ship.postime, text, '', ship.selected, res, ship.forecast)
				}
			})
      let forecastSource = new VectorSource();
			let forecastLayer = new VectorLayer({
        zIndex: 2,
        source: forecastSource,
        style: feature => {
          if(feature.getGeometry() instanceof Point){
            return new Style({
              image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                  color: 'blue'
                }),
                stroke: new Stroke({
                  color: 'black'
                })
              })
            })
          }else {
            return new Style({
              stroke: new Stroke({
                color: 'blue',
                lineDash: [5,5]
              })
            })
          }
        }
      })
			let cameraSource = new VectorSource();
			let cameraLayer = new VectorLayer({
				source: cameraSource,
				style: (feature) => {
					return new Style({
						image: new Icon({
							src: require('@/assets/images/camera' + (feature.values_.data.deviceStatus != '1' ? '-offline' : '') + '.png'),
							scale: 0.9
						}),
						text: new Text({
							text: feature.get('data').name,
							textBaseline: 'top',
							offsetX: 40,
							stroke: new Stroke({
								color: '#fff',
								width: 5
							}),
							fill: new Fill({
								color: 'rgb(0,72,255,1)'
							})
						}),
						zIndex: 0
					})
				}
			})
			let locatorSource = new VectorSource();
			let locatorLayer = new VectorLayer({
				source: locatorSource,
				style: (feature) => {
					var device = feature.get('data')
					let styles = [new Style({
						image: new Icon({
							src: require('@/assets/images/map/locator.png'),
							scale: 1
						}),
						text: new Text({
							offsetX: 25,
							offsetY: -25,
							text: device.deviceName,
							textAlign: 'left',
							backgroundFill: new Fill({
								color: [255, 255, 255, .6]
							}),
							backgroundStroke: new Stroke({
								color: [0, 0, 0],
								width: 1
							}),
							padding: [2, 4, 2, 4]
						}),
						zIndex: 0
					})];
					if(device.selected) {
						styles.push(new Style({
							image: new Icon({
								src: require('@/assets/images/map/select.png'),
								scale: 1
							})
						}))
					}
					return styles
				}
			})
			let customShipSource = new VectorSource();
			let customShipLayer = new VectorLayer({
				source: customShipSource,
				zIndex: 2,
				style: (feature, res) => {
					var ship = feature.get('data')
					var text = '' + (ship.shipNameCh || ship.shipNameEn || ship.shipName || ship.mmsi || ship.TRUCK_NUM);
					var hasCamera = ship.cameraId != null
					var textStyles = [
            new Style({
              text: new Text({
                offsetX: (hasCamera ? 61 : 20),
                offsetY: -20,
                text: text,
                textAlign: 'left',
                backgroundFill: new Fill({
                  color: [255, 255, 255, 1]
                }),
                backgroundStroke: new Stroke({
                  color: [39, 112, 215, 1],
                  width: 1
                }),
                padding: [4, 3, 2, 19 + (hasCamera ? 28 : 0)],
                font: '12px sans-serif'
              }),
              zIndex: 4
            }),
						new Style({
							text: new Text({
								offsetX: 7,
								offsetY: -20,
								text: '工',
								textAlign: 'center',
								backgroundFill: new Fill({
									color: [39, 112, 215, 1]
								}),
								backgroundStroke: new Stroke({
									color: [39, 112, 215, 1],
									width: 1
								}),
								fill: new Fill({
									color: 'white'
								}),
								padding: [4, 3, 2, 3],
								font: '12px sans-serif'
							}),
							zIndex: 5
						}),
          ]
					if(hasCamera){
						textStyles.push(new Style({
							text: new Text({
								offsetX: 33,
								offsetY: -20,
								text: 'VID',
								textAlign: 'center',
								fill: new Fill({ color: [39, 112, 215, 1]}),
								padding: [4, 1, 2, 5],
								font: '12px sans-serif',
								backgroundStroke: new Stroke({
									color: [39, 112, 215, 1],
									width: 1
								}),
							}),
							zIndex: 5
						}))
					}
          return this.getShipStyle(feature.getGeometry().getCoordinates(), ship.breadth, ship.shipLength, ship.speed, ship.heading, ship.course, ship.postime, textStyles, 'red', ship.selected, res, ship.forecast)
				}
			})
			let enclosureSource = new VectorSource({
				wrapX: false
			})
			let enclosureLayer = new VectorLayer({
				source: enclosureSource,
				zIndex: 0,
				style: (feature) => {
					var data = feature.get('data')
					return new Style({
						fill: new Fill({
							color: 'rgba(255, 255, 255, 0.7)',
						}),
						stroke: new Stroke({
							color: 'red',
							width: 2
						}),
						text: new Text({
							text: data.name,
							font: '14px sans-serif',
							fill: new Fill({
								color: 'red'
							}),
							offsetY: -15,
							stroke: new Stroke({
								color: 'white',
								width: 5
							})
						}),
						image: new CircleStyle({
							radius: 7,
							fill: new Fill({
								color: 'red'
							})
						})
					})
				}
			})
			let baseLayer = new GroupLayer({
				layers: [new TileLayer({
						source: new XYZ({
							url: 'https://t{0-3}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=a5ccddf1da15b533ec7e38b719fb878f',
							projection: 'EPSG:3857'
						}),
						isGroup: true,
						name: '影像底图'
					}),
					new TileLayer({
						source: new XYZ({
							url: 'https://t{0-3}.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=a5ccddf1da15b533ec7e38b719fb878f',
							projection: 'EPSG:3857'
						}),
						isGroup: true,
						name: '地形注记'
					})
				],
				visible: false
			})
			var defaultCenter = fromLonLat([107.05, 29.77]);
			var defaultZoom = 12;
			return {
				historyDevices: [],
				moreShipInfo: false,
				moreShipInfoBtn: '更多↓',
				historyTime: [],
				map: {},
				draw: '',
				baseLayer,
				drawSource,
				drawVector,
        measureing: false,
				areaSource,
				areaVector,
				beachRiskSource,
				beachRiskVector,
				waterLevelSource,
				waterLevelVector,
				alarmSource,
				alarmVector,
				carSource,
				carVector,
				markerSource,
				markerLayer,
				shipSource,
				shipLayer,
				trackSource,
				trackLayer,
				cameraSource,
				cameraLayer,
				locatorSource,
				locatorLayer,
				customShipSource,
				customShipLayer,
				enclosureSource,
				enclosureLayer,
        forecastLayer,
        forecastSource,
        forecastEnabled: false,
				ChannelLayer,
				DxfLayer,
				anchorLayer,
				anchorSource,
        epsg3857: getProj('EPSG:3857'),
				shipTypeFilter: {
					'货船': true,
					'客船': true,
					'油船': true,
					'集装箱船': true,
					'渔船': true,
					'高速船': true,
					'引航船': true,
					'工作船': true,
					'地效应船': true,
					'其它': true
				},
				featureStyle: this.getFeatureStyle(),
				featureStyleDialog: false,
				selectedDrawType: '',
				projects: [],
				toolBarStyle: 'width:70px',
				informationBarStyle: 'width:auto',
				toolbarHidden: false,
				toolShow: false,
				informationShow: true,
				filterShow: false,
				noticeShow:false,
				tableShow:false,
				drawDialogFormVisible: false,
				dialogBeachRiskInfoVisible: false,
				dialogWaterLevelInfoVisible: false,
				dialogAlarmInfoVisible:false,
				toolbox: [{
						name: '卫星',
						selected: false,
						icon: 'weixingtu',
						layer: baseLayer
					},
					{
						name: '航道',
						selected: true,
						icon: 'hangdao',
						layer: ChannelLayer
					},
					{
						name: '航标',
						selected: true,
						icon: 'hangbiao',
						layer: markerLayer
					},
					{
						name: '锚地',
						selected: true,
						icon: 'maodi',
						layer: anchorLayer
					},
					{
						name: 'AIS',
						selected: true,
						icon: 'diqiu-copy',
						layer: shipLayer
					},
					{
						name: '工程',
						selected: true,
						icon: 'gongcheng',
						layer: DxfLayer
					},
					{
						name: '视频',
						selected: true,
						icon: 'shexiangtou',
						layer: cameraLayer
					},
					{
						name: '标注',
						selected: true,
						icon: 'duobianxing',
						layer: areaVector
					},
					{
						name: '船舶',
						selected: true,
						icon: 'chuanbo',
						layer: customShipLayer
					},
					{
						name: '围栏',
						selected: true,
						icon: 'dianziweilan',
						layer: enclosureLayer
					},
					{
						name: '报警',
						selected: true,
						icon: 'weixianxinhao',
						layer: alarmVector
					}
				],
				draws: [{
						name: '点',
						icon: 'dian',
						type: 'Point'
					},
					{
						name: '线',
						icon: '_mars_xian',
						type: 'LineString'
					},
					{
						name: '面',
						icon: 'duobianxing',
						type: 'Polygon'
					},
					{
						name: '圆',
						icon: 'yuan',
						type: 'Circle'
					}
				],
				drawFeature: null,
				defaultMousePosition: '30°41.050′N<br>114°27.767′E',
				duration: 3000,
				dialogShipInfoVisible: false,
				dialogCarInfoVisible:false,
				shipDetail: {},
				searchOption: {
					options: [],
					value: [],
					loading: false
				},
				defaultCenter: defaultCenter,
				defaultZoom: defaultZoom,
				mapView: {},
				selectedFeature: '',
				locatorData: [],
				cameraData: [],
				selectFeatureValue: '',
				selectAlarmFeatureValue:'',
				shipActive: 'shipInfo',
				carActive: 'carInfo',
				beackRiskActive: 'beackRiskImg',
				shipPersonnelData:[],
				jobInformationData:[],
				inputDate:'',
				noticeList:[],//航行航道通告
				noticeTitle:'',//公告标题
				tableTitle:'',
				tableData:'',
				columnData:'',
				pdfUrl:'',
				pdfTitle:'',
				pdfShow:false,
				carDetail:{},
				beachRiskDetail:{},
				progressIntroduceDetail:{},
				waterLevelDetail:{},
				alarmDetail:{},
				waterLevelInputDate:[],
			}
		},
		created() {
			_this = this
			_this.flash()
			_this.flashAlarm()
			this.anchorLoad()
			this.markerLoad()
			// getDevicePos().then(res => {
			//   const data = res.data
			//   if (data) {
			//     for (let i = 0; i < data.length; i++) {
			//       let locator = data[i]
			//       this.jsonToHump(locator)
			//       let locatorFeature = _this.locatorSource.getFeatureById(locator.deviceId)
			//       if (!locatorFeature) {
			//         locatorFeature = new Feature({
			//           geometry: new Point(fromLonLat([locator.longitude / 600000, locator.latitude / 600000]))
			//         })
			//         locatorFeature.setId(locator.deviceId);
			//         locator.mmsi = locator.deviceId;
			//         this.locatorData.push(locator)
			//         locatorFeature.set('data', locator);
			//         locatorFeature.set('type', 'ship');
			//         locatorFeature.set('locator', 'true');
			//         _this.locatorSource.addFeature(locatorFeature);
			//       }
			//     }
			//   }
			// })
			getCameraList().then((response) => {
				const {
					data
				} = response;
				this.cameraData = data
				for(let i = 0; i < data.length; i++) {
					let coordinate = [Number(data[i].longitude), Number(data[i].latitude)];
					_this.cameraSource.addFeature(new Feature({
						geometry: new Point(coordinate).transform("EPSG:4326", "EPSG:3857"),
						data: data[i],
						type: 'camera'
					}));
				}
			})
			getCustomAreas().then((response) => {
				const {
					data
				} = response
				_this.areaSource.clear();
				for(let i = 0; i < data.length; i++) {
					let area = data[i]
					let geometry = new Polygon([JSON.parse(area.coordinates)]);
					if(area.areaType === 'LineString') {
						geometry = new LineString(JSON.parse(area.coordinates));
					} else if(area.areaType === 'Circle') {
						let coordinates = JSON.parse(area.coordinates);
						geometry = new Circle([coordinates[0], coordinates[1]], coordinates[2]);
					} else if(area.areaType === 'Point') {
						geometry = new Point(JSON.parse(area.coordinates));
					}
					let feature = new Feature({
						geometry: geometry.transform("EPSG:4326", "EPSG:3857")
					})
					feature.setId(area.id);
					feature.set('type', 'customArea')
					feature.set('data', area);
					_this.areaSource.addFeature(feature);
				}
			})
			getBeachRiskList().then((response) => {
				const {
					data
				} = response
				_this.beachRiskSource.clear();
				for(let i = 0; i < data.length; i++) {
					let beachRisk = data[i]
					beachRisk.url = beachRisk.url != null ? beachRisk.url.replace("https://chaofu-oss.oss-cn-beijing.aliyuncs.com/", "") : null
					if(beachRisk.longitude != null && beachRisk.latitude != null) {
						let coordinate = [Number(beachRisk.longitude), Number(beachRisk.latitude)];
						let geometry = new Point(coordinate);
						let feature = new Feature({
							geometry: geometry.transform("EPSG:4326", "EPSG:3857")
						})
						feature.setId(beachRisk.id);
						feature.set('type', 'beachRisk')
						feature.set('data', beachRisk);
						_this.beachRiskSource.addFeature(feature);
					}
				}
			})
			getWaterLevelList().then((response) => {
				const {
					data
				} = response
				_this.waterLevelSource.clear();
				for(let i = 0; i < data.length; i++) {
					let waterLevel = data[i]
					let geometry = new Point(JSON.parse(waterLevel.coordinates));
					let feature = new Feature({
						geometry: geometry.transform("EPSG:4326", "EPSG:3857")
					})
					feature.setId(waterLevel.waterSite);
					feature.set('type', 'waterLevel')
					feature.set('data', waterLevel);
					_this.waterLevelSource.addFeature(feature);
				}
			})
			enclosureList().then(res => {
				const { data } = res
				if(data && data.length > 0){
					for(let i = 0; i < data.length; i++) {
						let enclosure = data[i]
						let geometry = new Polygon([JSON.parse(enclosure.coordinates)]);
						let feature = new Feature({
							geometry: geometry.transform("EPSG:4326", "EPSG:3857")
						})
						feature.setId(enclosure.id);
						feature.set('type', 'enclosure')
						feature.set('data', enclosure);
						_this.enclosureSource.addFeature(feature);
					}
				}
			})
		},
		async mounted() {
			window.setMapCenter = _this.setMapCenter;
			window.getAlarmList = _this.getAlarmList
			var viewExtent = []
			await this.$request({
				url: 'extent/settings/list',
				method: 'post'
			}).then(res => {
				viewExtent = JSON.parse(res.data[0].extent)
				if (res.data[0].point) {
					_this.defaultCenter = fromLonLat(JSON.parse(res.data[0].point))
				}
			})
			this.mapView = new View({
				center: _this.defaultCenter,
				projection: "EPSG:3857",
				zoom: _this.defaultZoom,
				extent:  transformExtent(viewExtent, 'EPSG:4326', 'EPSG:3857'),
				maxZoom: 18,
				minZoom: 5
			})
			var layers = [
				this.baseLayer,
				this.ChannelLayer,
				this.DxfLayer,
				this.markerLayer,
				this.shipLayer,
				this.locatorLayer,
				this.customShipLayer,
				this.trackLayer,
				this.cameraLayer,
				this.anchorLayer,
				this.drawVector,
				this.areaVector,
				this.beachRiskVector,
				this.waterLevelVector,
				this.alarmVector,
				this.carVector,
				this.enclosureLayer,
        this.forecastLayer
			]
			if(this.layerMenu && this.layerMenu.length > 0){
				this.toolbox.forEach(it => {
					it.layer.setVisible(false)
				})
				var toolbox = []
				this.layerMenu.forEach(it => {
					eval('_this.'+ it.url +'.setVisible('+ (it.isShow == 1) +')')
					toolbox.push({
						name: it.name,
						selected: (it.isShow == 1),
						icon: it.icon,
						layer: eval('_this.'+ it.url)
					})
				})
        toolbox.push({
          name: '预测',
          selected: false,
          icon: 'guijichaxun',
          layer: {
            setVisible: (visible)=>{
              this.forecastEnabled = visible
              this.map.getLayers&&this.map.getLayers().forEach(it => it.getSource&&it.getSource().changed());
            }
          }
        })
				this.toolbox = toolbox
			}
			// this.ChannelLayer.setVisible(false)
			// 武汉市中心经纬度
			this.map = new Map({
				target: this.$refs.map,
				layers: layers,
				view: this.mapView
			})
			const scaleLine = new ScaleLine({
				units: 'metric',
				target: document.getElementById('scaleLine')
			});
			this.map.addControl(scaleLine);
			//鼠标获取坐标控件
			const mousePositionControl = new MousePosition({
				coordinateFormat: function(coordinate) {
					const hdms = _this.formateMousePosition(toStringHDMS(coordinate));
					_this.defaultMousePosition = hdms.y + '<br />' + hdms.x;
				},
				projection: 'EPSG:4326',
				className: 'mouse-position',
				target: document.getElementById('mouse-position'),
				undefinedHTML: false
			});

			this.map.on('singleclick', function(evt) {
				var ret = _this.map.forEachFeatureAtPixel(_this.map.getEventPixel(evt.originalEvent), function(feature, layer) {
					return [feature, layer]
				}, {
					hitTolerance: 10
				});
				_this.areaSource.getFeatures().forEach(it => it.get('data').selected = false)
				_this.selectedFeature = ''
				if(ret && ret[0]) {
					var feature = ret[0];
					var type = feature.get('type')
					var data = feature.get('data')
					switch(type) {
						case 'ship':
							_this.shipActive = 'shipInfo'
							_this.selectShip(feature)
							_this.getShipPersonnelData(data.id)
							_this.getJobInformationData(data.id)
							break;
						case 'car':
							_this.selectCar(feature)
							break;
						case 'customArea':
							_this.selectedFeature = feature
							data.selected = true
							break;
						case 'beachRisk':
							_this.selectedFeature = feature
							data.selected = true
							_this.getProgressIntroduceData(data)
							_this.beachRiskDetail = data
							break;
						case 'waterLevel':
							_this.selectedFeature = feature
							data.selected = true
							_this.waterLevelDetail = data
							_this.dialogWaterLevelInfoVisible = true
							_this.getWaterSiteData(data)
							break;
						case 'alarm':
							_this.selectedFeature = feature
							/*data.selected = true
							_this.selectAlarmFeatureValue = data.id
							_this.alarmDetail = data
							_this.dialogAlarmInfoVisible = true*/
							_this.selectAlarm(feature)
							break;
						case 'camera':
							// parent && parent.openCamera && parent.openCamera(data.cameraId, data.name)
							break;
						default:
							break;
					}
					_this.singleClick && _this.singleClick(type, data)
				}
				_this.areaSource.changed()
			})

			//添加控件到地图
			this.map.addControl(mousePositionControl);
			bus.$on('updateMapSize', () => this.$nextTick(() => this.map.updateSize()))
			this.map.on("moveend", this.getShips);
			setInterval(this.getShips, 5000)
			setInterval(this.getCustomCars, 5000)
			this.getShips()
			this.getCustomCars()
			this.shipSource.changed()
		},
		methods: {
      openShipCamera(cameraId){
        var camera = this.cameraData.find(it => it.id == cameraId)
        this.singleClick && this.singleClick('camera', camera)
      },
			getDeviceType(type){
				if(type == '1'){
					return 'AIS'
				}
				if(type == '2'){
					return '北斗'
				}
				if(type == '3'){
					return '北斗北极星'
				}
				if(type == '4'){
					return '索工'
				}
				if(type == '5'){
					return '808'
				}
				return '未知'
			},
			getProgressIntroduceData(data){
				this.progressIntroduceDetail = []
				getProgressIntroduceData({name:data.name}).then((response) => {
					const {
						data
					} = response
					this.progressIntroduceDetail = data
					_this.dialogBeachRiskInfoVisible = true
				})
			},
			getAlarmList(data){
              _this.alarmSource.clear();
              let find = false;
              for(let i = 0; i < data.length; i++) {
                  let alarm = data[i]
                  alarm.lat = (alarm.latitude/600000).toFixed(3)
                  alarm.lon = (alarm.longitude/600000).toFixed(3)
                  let geometry = new Point([alarm.lon,alarm.lat]);
                  let feature = new Feature({
                      geometry: geometry.transform("EPSG:4326", "EPSG:3857")
                  })
                  feature.setId(alarm.id);
                  feature.set('type', 'alarm')
                  if(alarm.id === _this.selectAlarmFeatureValue){
                      alarm.selected = true
                      find = true
                  }
                  feature.set('data', alarm);
                  _this.alarmSource.addFeature(feature);
              }
              if(!find) {
                _this.dialogAlarmInfoVisible = false
              }
			},
			getNoticeList(title){//航行航道通告
				this.noticeTitle = title
				this.noticeList = []
				if(title == '航行航道通告'){
					sailChannelNoticsList({current: 1,size: 10}).then((response) => {
						const {
							data
						} = response;
						this.noticeList = data
						this.noticeShow = true
					})
				} else if(title == '航道维护尺度'){
					maintenanceScaleList({current: 1,size: 10}).then((response) => {
						const {
							data
						} = response;
						this.noticeList = data
						this.noticeShow = true
					})
				} else if(title == '安全信息预警'){
					safetyWarningList({current: 1,size: 10}).then((response) => {
						const {
							data
						} = response;
						this.noticeList = data
						this.noticeShow = true
					})
				} else if(title == '应急管理'){
					contingencyManagementList({current: 1,size: 10}).then((response) => {
						const {
							data
						} = response;
						if(data != null){
							this.noticeList = data
						}else{
							this.noticeList = []
						}
						this.noticeList.push({title:'应急处置'})
						this.noticeShow = true
					})
				}
			},
			getTableList(title){
				this.tableTitle = title
				if(title == '施工船舶'){
					shipList({current: 1,size: 999999}).then((response) => {
						const {
							data
						} = response;
						this.tableData = data
						this.columnData = [
						{
							prop: 'name',
							label: '船舶名称'
						}, {
							prop: 'typeName',
							label: '船舶类型'
						}, {
							prop: 'shipLen',
							label: '船长'
						}, {
							prop: 'profileWidth',
							label: '型宽'
						}, {
							prop: 'fullDraft',
							label: '满载吃水'
						}, {
							prop: 'grossTonnage',
							label: '总吨位'
						}, {
							prop: 'contacts',
							label: '联系人'
						}, {
							prop: 'contactInfo',
							label: '联系方式'
						}]
						this.tableShow = true
					})
				}else{
					emergencyMisposalList({current: 1,size: 999999}).then((response) => {
						const {
							data
						} = response;
						this.tableData = data
						this.columnData = [
						{
							prop: 'title',
							label: '名称'
						}, {
							prop: 'content',
							label: '内容'
						}, {
							prop: 'happenDate',
							label: '发生日期'
						}, {
							prop: 'constructionSite',
							label: '施工地点'
						}, {
							prop: 'issueMatters',
							label: '问题事项'
						}, {
							prop: 'emergencyMeasure',
							label: '应急措施'
						}, {
							prop: 'fillingPersonnel',
							label: '填报人员'
						}]
						this.tableShow = true
					})
				}
			},
			tableCellClick(row){
				if(row.url != null){
					this.pdfPreview(row)
				}
			},
			pdfPreview(data){
				this.pdfTitle = data.title
				filePreview(data.url).then(res => {
						//这儿的res是返回的文件流
						console.log(res)
						var binaryData = [];
						binaryData.push(res);
						let pdfUrl = null;
						console.log(binaryData)
						try {
							pdfUrl = window.webkitURL.createObjectURL(new Blob(binaryData, {
								type: 'application/zip'
							}));
						} catch(error) {
							console.log(error);
						}
						setTimeout(() => {
							this.pdfUrl = encodeURIComponent(pdfUrl) + '&.pdf?v=' + Math.random()
							console.log(this.pdfUrl)
							this.pdfShow = true
						}, 100)
					});
			},
			updateMapSize() {
				this.$nextTick(() => this.map.updateSize())
			},
      doMeasure(){
        this.measureing = !this.measureing
        if(this.measureing){
          addToMap(this.map)
        }else{
          removeToMap(this.map)
        }
      },
      resetCenter() {
          this.mapView.setCenter(this.defaultCenter)
          this.mapView.setZoom(this.defaultZoom)
      },
      setMapCenter(options,mmsi,alarmId) {
        if(options != null){
          this.mapView.setCenter(options.transform ? fromLonLat(options.center) : options.center)
          this.mapView.setZoom(options.zoom)
        }
        if(mmsi != null) {
			var feature = this.getCurrFeature(mmsi)
			if(feature != null) {
				this.selectShip(feature, true)
			}else{
				this.$message.warning('暂无该船舶信息');
			}

		}
        if(alarmId != null) {
			var alarmFeature = this.getAlarmFeature(alarmId)
			if(alarmFeature != null) {
				this.selectAlarm(alarmFeature, true)
			} else {
				this.$message.warning('暂无该工地报警信息');
			}
		}
      },
      trackForecast(mmsi){
        console.log(mmsi)
        /*let end = new Date().getTime() / 1000;
        let start = end - 100;
        this.forecastSource.clear()
        getAisHistory(mmsi, start, end,2).then(res => {
          let track = res.data || []
          if(track && track.length === 2) {
            let prev = track[1];
            let current = track[0];
            if(prev.speed > 10 && current.speed > 10){
              let course = (current.course + prev.course) / 20
              let speed = (prev.speed + current.speed) / 20
              let lon = current.longitude / 600000.0
              let lat = current.latitude / 600000.0
              let linestring = [fromLonLat([lon, lat])]
              let angle = (course) * Math.PI / 180;
              let dst = speed * 1852 / 60 * 3;
              let R = 6371e3
              let ff = dst/R;
              // 转换为radian，否则结果会不正确
              let startLong = lon * Math.PI / 180;
              let startLat = lat * Math.PI / 180;
              lat = Math.asin(Math.sin(startLat)*Math.cos(ff)+Math.cos(startLat)*Math.sin(ff)*Math.cos(angle));
              lon = startLong + Math.atan2(Math.sin(angle)*Math.sin(ff)*Math.cos(startLat),Math.cos(ff)-Math.sin(startLat)*Math.sin(lat));
              lon = lon * 180.0 / Math.PI;
              lat = lat * 180.0 / Math.PI;
              linestring.push(fromLonLat([lon, lat]))
              this.forecastSource.clear();
              let features = []
              features.push(new Feature({
                geometry: new LineString(linestring)
              }))
              features.push(new Feature({
                geometry: new Point(fromLonLat([lon, lat]))
              }))
              this.forecastSource.addFeatures(features)
            }
          }
        })*/
      },
			queryHistory(mmsi, devices) {
				devices = devices || []
				this.trackSource.clear()
				if(this.historyTime && this.historyTime.length === 2 && this.historyTime[0] && this.historyTime[1]) {
					let startTime = this.historyTime[0].getTime() / 1000
					let endTime = this.historyTime[1].getTime() / 1000
					getAisHistory(mmsi, startTime, endTime, devices).then(res => {
						if(res.data && res.data.length > 0) {
							let coordinates = [];
							let features = [];
							for(let i = 0, len = res.data.length; i < len; i++) {
								let item = res.data[i];
								let coordinate = fromLonLat([item.longitude / 600000, item.latitude / 600000])
								coordinates.push(coordinate)
								features.push(new Feature({
									geometry: new Point(coordinate),
									text: (devices.length > 1 ? `(${this.getDeviceType(item.device)})` : '') + this.dateFormat('yyyy-MM-dd HH:mm:ss', new Date(item.postime * 1000)) + (item.speed ? '  ' + (item.speed / 10) + '节' : '')
								}))
							}
							console.log('queryHistory')
							features.push(new Feature({
								geometry: new LineString(coordinates)
							}))
							this.trackSource.addFeatures(features)
						} else {
							this.$message.warning('该时间段内无位置信息');
						}
					})
				}
			},
			getShipTypeName(value) {
				if(isNaN(value)) {
					return "其它";
				} else {
					switch(parseInt(value / 10)) {
						case 2:
							return "地效应船";
						case 3:
							if(value === 30) {
								return "渔船";
							}
							break;
						case 4:
							return "高速船";
						case 5:
							if(value === 50) {
								return "引航船";
							}
							break;
						case 6:
							return "客船";
						case 7:
							return "货船";
						case 8:
							return "油船";
						case 10:
							return "集装箱船";
					}
					if(value === 33 || value === 34 || (value > 53 && value < 60)) {
						return "工作船";
					}
					return "其它"
				}
			},
			getFeatureStyle() {
				return {
					name: '',
					textStyle: 'rgb(0,72,255,1)',
					textStrokeStyle: 'rgb(255,255,255,1)',
					fillStyle: 'rgba(255,0,0,.6)',
					strokeStyle: 'rgba(255,0,0,1)',
					icon: '',
					iconDetail: {},
					iconWidth: 0,
					iconHeight: 0
				}
			},
			selectShip(feature, isCenter) {
				isCenter = isCenter === undefined ? true : isCenter
				_this.setNotSelectedFeatures()
				var data = feature.get('data')
				data.selected = true
				if(isCenter) {
					this.mapView.setCenter(feature.getGeometry().getCoordinates())
				}
        let selfId = data.mmsi || data.shipId
        let targetId = this.shipDetail?.position?.mmsi || this.shipDetail?.position?.shipId;
        if(selfId !== targetId){
          this.trackSource.clear()
          this.historyTime = [new Date(this.dateFormat('yyyy-MM-dd', new Date(data.postime * 1000 - 86400000)) + ' 00:00:00'), new Date(this.dateFormat('yyyy-MM-dd', new Date(data.postime * 1000)) + ' 23:59:59')]
          this.historyDevices = []
        }
				_this.sourceChange()
				if(feature.get('locator') !== 'true') {
					_this.selectFeatureValue = data.mmsi || data.shipId
					_this.getShipDetail(data)
				}
			},
			selectCar(feature) {
				this.mapView.setCenter(feature.getGeometry().getCoordinates())
				_this.setNotSelectedFeatures()
				var data = feature.get('data')
				data.selected = true
				this.carDetail = data
				this.carDetail.hdms = this.formateMousePosition(toStringHDMS([data.longitude, data.latitude]))
				this.historyTime = [new Date(this.dateFormat('yyyy-MM-dd', new Date(data.postime * 1000 - 86400000)) + ' 00:00:00'), new Date(this.dateFormat('yyyy-MM-dd', new Date(data.postime * 1000)) + ' 23:59:59')]
				this.historyDevices = []
				this.dialogCarInfoVisible = true
				_this.sourceChange()
			},
			selectAlarm(feature) {
				this.mapView.setCenter(feature.getGeometry().getCoordinates())
				_this.setNotSelectedFeatures()
				var data = feature.get('data')
				data.selected = true
				_this.selectAlarmFeatureValue = data.id
				_this.alarmDetail = data
				_this.dialogAlarmInfoVisible = data.message ? false : true
				_this.sourceChange()
			},
			sourceChange(){
				_this.shipSource.changed()
				_this.locatorSource.changed()
				_this.customShipSource.changed()
				_this.carSource.changed()
				_this.alarmSource.changed()
			},
			setNotSelectedFeatures(){
				_this.dialogShipInfoVisible = false
				_this.dialogCarInfoVisible = false
				_this.dialogAlarmInfoVisible = false
				_this.dialogWaterLevelInfoVisible = false
				_this.dialogBeachRiskInfoVisible = false
				_this.selectFeatureValue = ''
				_this.selectAlarmFeatureValue = ''
				_this.shipSource.getFeatures().forEach(it => it.get('data').selected = false)
				_this.locatorSource.getFeatures().forEach(it => it.get('data').selected = false)
				_this.customShipSource.getFeatures().forEach(it => it.get('data').selected = false)
				_this.carSource.getFeatures().forEach(it => it.get('data').selected = false)
				_this.alarmSource.getFeatures().forEach(it => it.get('data').selected = false)
			},
			closeShipDetail() {
				this.searchOption.value = ''
				_this.selectFeatureValue = ''
				_this.setNotSelectedFeatures()
				_this.sourceChange()
				this.trackSource.clear()
				this.historyDevices = []
				this.dialogShipInfoVisible = false
			},
			closeCarDetail() {
				_this.selectFeatureValue = ''
				_this.setNotSelectedFeatures()
				_this.sourceChange()
				this.trackSource.clear()
				this.carDetail = {}
				this.dialogCarInfoVisible = false
			},
			closeBeachRiskDetail(){
				this.dialogBeachRiskInfoVisible = false
				this.beachRiskDetail = {}
			},
			closeWaterLevelDetail(){
				this.dialogWaterLevelInfoVisible = false
				this.waterLevelDetail = {}
			},
			closeAlarmDetail(){
				this.dialogAlarmInfoVisible = false
				_this.setNotSelectedFeatures()
				_this.sourceChange()
				this.alarmDetail = {}
			},
			getCurrFeature(value) {
				let feature = this.shipSource.getFeatures().filter(it => it.get('data').mmsi + '' === value + '')[0];
				feature = feature || this.shipSource.getFeatures().filter(it => it.get('data').shipId + '' === value + '')[0] ||
					this.locatorSource.getFeatures().filter(it => it.get('data').mmsi + '' === value + '')[0] ||
					this.customShipSource.getFeatures().filter(it => it.get('data').shipId + '' === value + '')[0];
				return feature
			},
			getAlarmFeature(value) {
				let feature = this.alarmSource.getFeatures().filter(it => it.get('data').id + '' === value + '')[0];
				return feature
			},
			shipSearchSelect(value) {
				var values = value.split('!@#')
				var type = values[0] || ''
				value = values[1] || ''
				if(type === 'ship') {
					this.shipActive = 'shipInfo'
					this.selectShip(this.getCurrFeature(value))
					this.getShipPersonnelData(value)
					this.getJobInformationData(value)
				}
				if(type === 'car') {
					let feature = this.carSource.getFeatures().filter(it => it.get('data').id + '' === value + '')[0];
					this.selectCar(feature)
				}
				if(type === 'camera') {
					let feature = this.cameraSource.getFeatures().filter(it => it.get('data').id + '' === value + '')[0];
					this.mapView.setCenter(feature.getGeometry().getCoordinates())
					// parent && parent.openCamera && parent.openCamera(feature.get('data').cameraId, feature.get('data').name)
					this.singleClick && this.singleClick(type, feature.get('data'))
				}
			},
			async remoteMethod(query) {
				if(query !== '') {
					this.searchOption.options = [{
						label: 'AIS',
						options: []
					}, {
						label: '船舶',
						options: []
					}, {
						label: '摄像头',
						options: []
					}, {
						label: '定位器',
						options: []
					}, {
						label: '车辆',
						options: []
					}];
					this.shipSource.getFeatures().forEach(it => {
						var data = it.get('data')
						if(((data.shipName && data.shipName.indexOf(query) != -1) || (data.mmsi && (data.mmsi + '').indexOf(query) != -1)) && this.searchOption.options[0].options.length < 5) {
							this.searchOption.options[0].options.push({
								label: data.shipName ? data.shipName + `（${data.mmsi}）` : data.mmsi,
								value: `ship!@#${data.mmsi}`
							})
						}
					})
					this.customShipSource.getFeatures().forEach(it => {
						var data = it.get('data')
						if(data.shipName && data.shipName.indexOf(query) != -1 && this.searchOption.options[1].options.length < 5) {
							this.searchOption.options[1].options.push({
								label: data.shipName,
								value: `ship!@#${data.shipId}`
							})
						}
					})
					this.carSource.getFeatures().forEach(it => {
						var data = it.get('data')
						if(data.carName && data.carName.indexOf(query) != -1 && this.searchOption.options[1].options.length < 5) {
							this.searchOption.options[4].options.push({
								label: data.carName,
								value: `car!@#${data.carId}`
							})
						}
					})
					var cameraIsOnline = (status) => {
						if(status != 1) {
							return '（不在线）'
						}
						return ''
					}
					this.cameraData.forEach(it => {
						if(it.name.indexOf(query) != -1 && this.searchOption.options[2].options.length < 5) {
							this.searchOption.options[2].options.push({
								label: it.name + cameraIsOnline(it.deviceStatus),
								value: `camera!@#${it.id}`
							})
						}
					})
					this.locatorData.forEach(it => {
						if(it.deviceName && it.mmsi && it.deviceName.indexOf(query) != -1 || it.mmsi.indexOf(query) != -1 && this.searchOption.options[3].options.length < 5) {
							this.searchOption.options[3].options.push({
								label: it.deviceName + `（${it.mmsi}）`,
								value: `${it.mmsi}`
							})
						}
					})
					console.log(this.searchOption)
					for(var i = this.searchOption.options.length - 1; i >= 0; i--) {
						if(this.searchOption.options[i].options.length == 0) {
							this.searchOption.options.splice(i, 1)
						}
					}
				}
			},
			getShipDetail(info) {
				// let mmsi = info.mmsi;
				this.shipDetail = {
					type: 'ship',
					position: info,
					imgUrls: (info.imgUrl != null ? info.imgUrl.split(",") : []).filter(it => it),
					postime: this.dateFormat('yyyy-MM-dd HH:mm:ss', new Date(info.postime * 1000)),
					hdms: this.formateMousePosition(toStringHDMS([info.longitude, info.latitude]))
				}
				console.log(this.shipDetail)
				this.dialogShipInfoVisible = true
				// _this.$request({
				//     url: '/ship/get',
				//     params: {
				//         device: mmsi
				//     }
				// }).then(shipGet => {
				//     if (shipGet.data) {
				//         _this.shipDetail = shipGet.data
				//         _this.shipDetail.position = info
				//         _this.dialogShipInfoVisible = true
				//     }
				// })
			},
			markerLoad() {
				markerList().then(res => {
					const {
						data
					} = res
					let features = [];
					for(let i = 0; i < data.length; i++) {
						let item = data[i];
						let feature = new Feature({
							geometry: new Point(fromLonLat([item.longitude, item.latitude]))
						});
						feature.setStyle(new Style({
							image: new Icon({
								src: 'http://47.94.247.48:8021/userfiles' + item.img
							}),
							text: new Text({
								text: item.name,
								textBaseline: 'top',
								offsetY: 18
							})
						}))
						features.push(feature);
					}
					this.markerSource.addFeatures(features);
				})
			},
			anchorLoad() {
				anchorList().then(res => {
					const { data } = res
					let features = [];
					for(let i = 0; i < data.length; i++) {
						let item = data[i];
						let geometry;
						var geojson = JSON.parse(item.geojson)[0];
						let style;
						if(geojson.type === 'point') {
							geometry = new Point(geojson.point)
							style = new Style({
								image: new CircleStyle({
									radius: 4,
									snapToPixel: false,
									fill: new Fill({
										color: '#FF0F0F'
									}),
									stroke: new Stroke({
										color: '#FF0F0F',
										width: 4
									})
								}),
								text: new Text({
									text: item.name,
									textBaseline: 'top',
									offsetY: 5
								})
							});
						} else {
							geometry = new Polygon([geojson.points]);
							style = new Style({
								fill: new Fill({
									color: 'rgba(255, 78, 78, 0.2)'
								}),
								stroke: new Stroke({
									color: '#FF0F0F',
									width: 1.5
								}),
								text: new Text({
									text: item.name,
									textBaseline: 'top',
									offsetY: 5
								})
							});
						}
						let feature = new Feature({
							geometry: geometry.transform("EPSG:4326", "EPSG:3857")
						})

						feature.setStyle(style);
						features.push(feature);
					}
					this.anchorSource.addFeatures(features);
				});
			},
			flash() {
				var start = new Date().getTime();
				this.locatorLayer.on('postrender', animate);
				function animate(event) {
					var vectorContext = getVectorContext(event);
					var frameState = event.frameState;
					var elapsed = frameState.time - start;
					var elapsedRatio = (elapsed % _this.duration) / _this.duration;
					// radius will be 5 at start and 30 at end.
					var radius = easeOut(elapsedRatio) * 25 + 5;
					var opacity = easeOut(1 - elapsedRatio);
					_this.locatorSource.getFeatures().forEach(feature => {
						let locator = feature.get('data')
						if(locator.postime > new Date().getTime() / 1000 - 1800) {
							var flashGeom = feature.getGeometry().clone();
							var style = new Style({
								image: new CircleStyle({
									radius: radius,
									stroke: new Stroke({
										color: 'rgba(255, 0, 0, ' + opacity + ')',
										width: 0.25 + opacity,
									}),
								}),
							});
							vectorContext.setStyle(style);
							vectorContext.drawGeometry(flashGeom);
						}
					})
					_this.map.render();
				}
			},
			flashAlarm() {
				var start = new Date().getTime();
				this.alarmVector.on('postrender', animateAlarm);
				function animateAlarm(event) {
					var vectorContext = getVectorContext(event);
					var frameState = event.frameState;
					var elapsed = frameState.time - start;
					var elapsedRatio = (elapsed % _this.duration) / _this.duration;
					// radius will be 5 at start and 30 at end.
					var radius = easeOut(elapsedRatio) * 20 + 5;
					var opacity = easeOut(1 - elapsedRatio);
					_this.alarmSource.getFeatures().forEach(feature => {
						var data = feature.get('data')
						if(data.selected || data.processed !== '1') {
							var flashGeom = feature.getGeometry().clone();
							var style = new Style({
								image: new CircleStyle({
									radius: radius,
									stroke: new Stroke({
										color: 'rgba(255, 0, 0, ' + opacity + ')',
										width: 0.25 + opacity,
									}),
								}),
							});
							vectorContext.setStyle(style);
							vectorContext.drawGeometry(flashGeom);
						}
					})
					_this.map.render();
				}
			},
			underline2Hump(s) {
				return s.replace(/_(\w)/g, function(all, letter) {
					return letter.toUpperCase()
				})
			},
			jsonToHump(obj) {
				if(obj instanceof Array) {
					obj.forEach(function(v) {
						_this.jsonToHump(v)
					})
				} else if(obj instanceof Object) {
					Object.keys(obj).forEach(function(key) {
						var newKey = _this.underline2Hump(key)
						if(newKey !== key) {
							obj[newKey] = obj[key]
							delete obj[key]
						}
						_this.jsonToHump(obj[newKey])
					})
				}
			},
			transformShip(ship) {
				ship.id = ship.mmsi || ship.car_no || ship.TRUCK_NUM || ship.id;
				ship.course = (ship.course !== undefined ? ship.course / 10 : undefined);
				ship.heading = ((ship.heading !== undefined && ship.heading != 511) ? ship.heading : undefined) || ship.direction;
				ship.speed = (ship.speed !== undefined ? ship.speed / 10 : undefined);
				ship.longitude = ship.longitude / 600000 || ship.lon || ship.LNG;
				ship.latitude = ship.latitude / 600000 || ship.LAT;
				ship.shipLength = ship.shipLength || ship.totalLen * 10 || ship.shipLen * 10
				ship.breadth = ship.breadth || ship.shipWidth || ship.profileWidth * 10
			},
			degreeAbs(val) {
				val = Math.abs(val);
				if(val > 180) {
					return 360 - val;
				} else {
					return val;
				}
			},
			getAngle(heading, speed, course) {
				if(heading > 0 && heading < 360) {
					if(speed >= 3 && this.degreeAbs(heading - course) >= 15) {
						if(course >= 0 && course < 360) {
							return course;
						} else {
							return heading;
						}
					} else {
						return heading;
					}
				} else if(course >= 0 && course < 360) {
					return course;
				}
				return 0;
			},
      getShipStyle(center, shipWidth, shipLength, speed, heading, course, postime, text, color, selected, res, forecast){
        let styles = [];
        let rw;
        let rh;
        let canDrawReal = false;
        shipWidth = (shipWidth || 0) / 10
        shipLength = (shipLength || 0) / 10
        if(!Array.isArray(text) && postime < new Date().getTime() / 1000 - 7200 && !selected){
          return null;
        }
        //判断可否按比例画船
        if(shipWidth&&shipLength&&shipLength <500){
          //获取一像素单位
          let meter = getPointResolution(this.epsg3857,res,center) * this.epsg3857.getMetersPerUnit();
          rh = shipLength/meter;
          rw = shipWidth/meter;
          let ratio = rh/rw;
          canDrawReal = meter>0&&rw>3&&rh>25&&ratio>2&&ratio<20;
        }
        styles.push(new Style({
          fill: new Fill({
            color: color || (postime > new Date().getTime() / 1000 - 1800 ? '#baff00' : '#b2b2b2')
          }),
          stroke: new Stroke({
            color: 'black'
          }),
          zIndex: (selected && 1 || 0) * 10,
          geometry: () =>{
            let pixel = this.map.getPixelFromCoordinate(center)
            let w = canDrawReal ? rw : 12;
            let h = canDrawReal ? rh : 20;
            let transform = (array)=>array.map(it => {
              return this.map.getCoordinateFromPixel([it[0] - w /2 + pixel[0], it[1] - h / 2 + pixel[1]])
            })
            let geoms = [new Polygon([
              transform(
                  canDrawReal ? [[w/2,0],[w*2/7,h/18],[0.5,h/6],[0.5,h*14/15],[w/6,h],[w*5/6,h],[w,h*14/15],[w,h/6],[w*5/7,h/18],[w/2,0]] : [[w/2,0],[0,h],[w,h],[w/2,0]]
              )
            ])];
            if(speed > 1){
              let len = Math.min(Math.max(5, parseInt(speed) * 3), canDrawReal ? 40 : 20)
              geoms.push(new LineString(transform([
                [w / 2, 0],
                [w / 2, -len]
              ])))
            }
            var collection = new GeometryCollection(geoms);
            collection.rotate(-this.getAngle(heading, speed, course) * Math.PI / 180, center)
            return collection;
          }
        }));
        if(Array.isArray(text)){
          text.map(it => {
            it = it.clone()
            it.setZIndex((it.getZIndex() || 1) + (selected && 1 || 0) * 10)
            styles.push(it)
          });
        }else if(res < 7.88 / 8 || selected){
          styles.push(new Style({
            text: new Text({
              offsetX: 20,
              offsetY: -20,
              text: text,
              textAlign: 'left',
              backgroundFill: new Fill({
                color: [255, 255, 255, .8]
              }),
              backgroundStroke: new Stroke({
                color: [213, 228, 252, 1],
                width: 1
              }),
              padding: [4, 3, 2, 6],
              font: '12px sans-serif'
            }),
            zIndex: 4 + (selected && 1 || 0) * 10
          }))
        }
        if (selected) {
          styles.push(new Style({
            image: new Icon({
              src: require('@/assets/images/map/select.png'),
              scale: 1,
              rotation: -this.getAngle(heading, speed, course) * Math.PI / 180
            }),
            zIndex: 2 + (selected && 1 || 0) * 10
          }))
        }
        if(forecast && this.forecastEnabled){
          styles.push(new Style({
            geometry: new LineString([fromLonLat(forecast),center]),
            stroke: new Stroke({
              color: 'blue',
              lineDash: [5,5]
            })
          }))
          styles.push(new Style({
            geometry: new Point(fromLonLat(forecast)),
            image: new CircleStyle({
              radius: 5,
              fill: new Fill({
                color: 'blue'
              }),
              stroke: new Stroke({
                color: 'black'
              })
            })
          }))
        }
        return styles;
      },
			getShips() {
				var extent = transformExtent(this.map.getView().calculateExtent(this.map.getSize()), this.map.getView().getProjection(), "EPSG:4326")
				getAisPositions(extent).then(res => {
					const data = res.data
					if(data) {
						for(let i = 0; i < data.length; i++) {
							let ship = data[i]
							_this.transformShip(ship);
							let shipFeature = _this.shipSource.getFeatureById(ship.id)
							if(shipFeature) {
								_this.shipSource.removeFeature(shipFeature)
							}
							shipFeature = new Feature({
								geometry: new Point(fromLonLat([ship.longitude, ship.latitude]))
							})
							shipFeature.setId(ship.id);
							ship.shipTypeName = this.getShipTypeName(ship.shipType)
							shipFeature.set('data', ship);
							shipFeature.set('type', 'ship');
							_this.shipSource.addFeature(shipFeature);
						}
						if(_this.selectFeatureValue) {
							_this.selectShip(_this.getCurrFeature(_this.selectFeatureValue), false)
						}
					}
				})
				this.getCustomShips();
			},
			getShipPersonnelData(shipId) {
				this.inputDate = this.dateFormat('yyyy-MM-dd', new Date())
				getShipAllPersonnelStatus(shipId && (shipId + '').replace('custom',''),this.inputDate).then(res => {
					this.shipPersonnelData =res.data != null ?  res.data : []
				})
			},
			getJobInformationData(shipId) {
				getShipDailyList((shipId + '').replace('custom','')).then(res => {
					this.jobInformationData =res.data != null ?  res.data : []
				})
			},
			getWaterSiteData(data) {
				let d = new Date()
				if(this.waterLevelInputDate.length == 0){
					this.waterLevelInputDate.push(this.dateFormat('yyyy-MM-dd', new Date(new Date().setDate(d.getDate()-7))))
					this.waterLevelInputDate.push(this.dateFormat('yyyy-MM-dd', d))
				}
				getWaterSiteList(data.waterSite, this.waterLevelInputDate[0], this.waterLevelInputDate[1]).then(res => {
					let data = res.data != null ?  res.data : []
					let xAxisData = []
					let seriesData = []
					for(let d of data){
						xAxisData.push(d.inputDate.split(' ')[0])
						seriesData.push(d.waterHeight)
					}
					var myChart = echarts.init(document.getElementById('waterSiteChart'))
					myChart.setOption({
						/*title: {
							text: _this.waterLevelDetail.waterSite,
							left: 'center'
						},*/
						grid: {
							top: '10%',
						},
						xAxis: {
							type: 'category',
							data: xAxisData
						},
						yAxis: {
							name: '水位值(m)',
							type: 'value',
							min: 140,
							max: 190,
						},
						series: [{
							data: seriesData,
							type: 'line',
							label: {
								show: true,
								position: 'top'
							}
						}]
					})
				})
			},
			getCustomShips() {
				// getShipInfo(4).then(res => {
				//   const {data} = res
				//   getShipPos(data.map(it => it.mmsi).join(',')).then(res => {
				getCustomShipList().then(res => {
					var ships = res.data
					ships.forEach(ship => {
						ship.shipName = ship.name || ship.mmsi
						var shipId = 'custom' + ship.id
						ship.shipId = shipId
						_this.transformShip(ship);
						let shipFeature = _this.customShipSource.getFeatureById(shipId)
						if(shipFeature) {
							_this.customShipSource.removeFeature(shipFeature)
						}
						shipFeature = new Feature({
							geometry: new Point(fromLonLat([ship.longitude, ship.latitude]))
						})
						shipFeature.setId(shipId);
						shipFeature.set('type', 'ship');
						shipFeature.set('data', ship);
						_this.customShipSource.addFeature(shipFeature);
					})
					if(_this.selectFeatureValue) {
						_this.selectShip(_this.getCurrFeature(_this.selectFeatureValue), false)
					}
				})
				//   })
				// })
			},
			getCustomCars() {
				getCustomCarList().then(res => {
					var cars = res.data
					cars.forEach(car => {
						car.postimeStr = this.dateFormat('yyyy-MM-dd HH:mm:ss', new Date(car.postime * 1000))
						car.imgUrls = (car.imgUrl != null ? car.imgUrl.split(",") : []).filter(it => it),
						car.carName = car.name
						var carId = 'custom' + car.id
						_this.transformShip(car);
						let carFeature = _this.carSource.getFeatureById(carId)
						if(carFeature) {
							car.selected = carFeature.get('data').selected
							_this.carSource.removeFeature(carFeature)
						}
						carFeature = new Feature({
							geometry: new Point(fromLonLat([car.longitude, car.latitude]))
						})
						carFeature.setId(carId);
						carFeature.set('type', 'car');
						carFeature.set('data', car);
						_this.carSource.addFeature(carFeature);
					})
					/*if(_this.selectFeatureValue) {
						_this.selectCar(_this.getCurrFeature(_this.selectFeatureValue), false)
					}*/
				})
				//   })
				// })
			},
			dateFormat(fmt, date) {
				let ret;
				const opt = {
					"y+": date.getFullYear().toString(), // 年
					"M+": (date.getMonth() + 1).toString(), // 月
					"d+": date.getDate().toString(), // 日
					"H+": date.getHours().toString(), // 时
					"m+": date.getMinutes().toString(), // 分
					"s+": date.getSeconds().toString() // 秒
					// 有其他格式化字符需求可以继续添加，必须转化成字符串
				};
				for(let k in opt) {
					ret = new RegExp("(" + k + ")").exec(fmt);
					if(ret) {
						fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
					}
				}
				return fmt;
			},
			formateMousePosition(args) {
				try {
					var index = Number(args.indexOf('N'));
					if(index == -1) {
						index = Number(args.indexOf('S'));
					}
					var strLat = args.substring(0, index + 1);
					var strLon = args.substring(index + 1, args.length);
					var arrLat = strLat.split(" ");
					var arrLon = strLon.split(" ");
					var fen = Number(arrLat[2].substring(0, arrLat[2].length - 1)) / 60;
					fen = Number(fen.toFixed(3));
					fen = Number(arrLat[1].substring(0, arrLat[1].length - 1)) + Number(fen);
					fen = fen.toFixed(3) + arrLat[1].substring(arrLat[1].length - 1, arrLat[1].length);
					var reLat = arrLat[0] + fen + arrLat[3];
					var fen2 = Number(arrLon[3].substring(0, arrLon[3].length - 1)) / 60;
					fen2 = Number(fen2.toFixed(3));
					fen2 = Number(arrLon[2].substring(0, arrLon[2].length - 1)) + Number(fen2.toFixed(3));
					fen2 = fen2.toFixed(3) + arrLon[2].substring(arrLon[2].length - 1, arrLon[2].length);
					var reLon = arrLon[1] + fen2 + arrLon[4];
					return {
						x: reLon,
						y: reLat
					};
				} catch(e) {
					console.log(e);
				}
			},
			doRemove() {
				var feature = this.selectedFeature
				if(!feature) {
					this.$message.error('请选择标注！');
					return
				}
				let id = feature.getId();
				console.log(id)
				this.$confirm('是否删除?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					removeArea(id).then(() => {
						this.areaSource.removeFeature(feature)
						this.selectedFeature = ''
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					})
				})
			},
			onSaveArea(flag) {
				this.featureStyleDialog = false;
				if(!flag) {
					this.drawSource.clear();
					return;
				}
				var geometry = this.drawFeature.getGeometry();
				geometry = geometry.clone().transform("EPSG:3857", "EPSG:4326");
				var coordinates = [];
				if(this.selectedDrawType !== 'Circle') {
					coordinates = geometry.getCoordinates();
					if(this.selectedDrawType === 'Polygon') {
						coordinates = coordinates[0];
					}
				} else {
					coordinates = geometry.getCenter();
					coordinates.push(geometry.getRadius());
				}
				this.featureStyle.iconWidth = this.featureStyle.iconDetail.width
				this.featureStyle.iconHeight = this.featureStyle.iconDetail.height
				saveArea({
					areaType: this.selectedDrawType,
					coordinates: JSON.stringify(coordinates),
					...this.featureStyle
				}).then((response) => {
					const {
						data
					} = response
					if(data) {
						this.drawSource.clear();
						let feature = new Feature({
							geometry: geometry.transform("EPSG:4326", "EPSG:3857")
						})
						feature.setId(data)
						feature.set('type', 'customArea')
						feature.set('data', {
							...this.featureStyle
						});
						this.areaSource.addFeature(feature);
						this.featureStyle = this.getFeatureStyle()
					}
				})
			},
			addArea(feature) {
				this.drawFeature = feature;
				this.featureStyleDialog = true;
			},
			changeDraw(type) {
				this.selectedDrawType = type
				this.drawSource.clear()
				this.map.removeInteraction(this.draw);
				this.draw = new Draw({
					source: this.drawSource,
					type: type,
				});
				this.draw.on('drawend', (e) => {
					this.draw.setActive(false);
					this.addArea(e.feature);
				});
				this.map.addInteraction(this.draw);
			},
			toolToggle() {
				this.toolShow = !this.toolShow;
				if(this.toolShow) {
					this.toolBarStyle = 'width:auto';
				} else {
					this.toolBarStyle = 'width:70px';
				}
			},
			informationToggle() {
				this.informationShow = !this.informationShow;
				if(this.informationShow) {
					this.informationBarStyle = 'width:auto';
				} else {
					this.informationBarStyle = 'width:70px';
				}
			},
			setBarShow(isShow){
				if(this.informationShow){
					this.informationShow = isShow
					this.informationToggle();
				}
				if(this.toolShow){
					this.toolShow = isShow
					this.toolToggle()
				}

			}
		},
		watch: {
      dialogShipInfoVisible: function(val){
        if(val){
          this.trackForecast(this.shipDetail.position.shipId || this.shipDetail.position.mmsi)
        }else{
          this.forecastSource.clear()
        }
      },
      'shipDetail.position.shipId': function(){
        if(this.dialogShipInfoVisible){
          this.trackForecast(this.shipDetail.position.shipId || this.shipDetail.position.mmsi)
        }
      },
      'shipDetail.position.mmsi': function(){
        if(this.dialogShipInfoVisible){
          this.trackForecast(this.shipDetail.position.shipId || this.shipDetail.position.mmsi)
        }
      },
      'shipDetail.position.postime': function(){
        if(this.dialogShipInfoVisible){
          this.trackForecast(this.shipDetail.position.shipId || this.shipDetail.position.mmsi)
        }
      }
    }
	}
</script>
<style>
  .ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
  }
  .ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
  }
  .ol-tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
  }
  .ol-tooltip-measure:before,
  .ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
  }
  .ol-tooltip-static:before {
    border-top-color: #ffcc33;
  }
	.el-dialog__body {
		padding: 10px 10px 0px 10px;
	}

	.ol-scale-line {
		background: rgba(255, 255, 255, .5);
	}

	.el-select-dropdown .el-select-group__title {
		background: #f5f5f5;
		border-bottom: 1px dotted #e1e1e1;
		font-weight: bold;
		font-size: 14px;
		color: #4c4c4c;
		line-height: 35px;
	}

	.el-select-dropdown__list {
		padding: 0px!important;
	}

	.el-select-group__wrap:not(:last-of-type) {
		padding-bottom: 0px!important;
	}

	.el-select-dropdown__item.hover,
	.el-select-dropdown__item:hover {
		background: rgb(246, 251, 255)!important;
		color: rgb(27, 143, 242);
	}

	.el-select-group__wrap:not(:last-of-type)::after {
		height: 0px!important;
	}

	.el-select-dropdown__item {
		font-size: 14px!important;
	}

	.el-select-dropdown__wrap {
		max-height: 400px!important;
	}

	.popper__arrow {
		display: none!important;
	}

	.el-tabs__item {
		height: 20px!important;
		line-height: 10px!important;
	}
	.el-table .cell{
		padding-left:5px!important;
		padding-right:5px!important;
		line-height:15px!important;
	}
	.el-table__row>td{ border: none; }

	.el-table::before { height: 0px; }
	.el-table__row .cell{
		font-size: 12;
		color: black;
	}
	.el-dialog__header{
		background-color: #0f89f5;
		color: white;
		padding:10px 10px 10px 10px !important;
	}
	.el-dialog__title{
		color: white!important;
	}
	.el-dialog__headerbtn{
		top:15px!important;
	}
	.el-dialog__headerbtn .el-dialog__close {
		color: white!important;
	}
	.image-slot{
		font-size: 80px;
		color: #888888;
	}
</style>
<style scoped>
	.el-dialog__wrapper>>>.el-dialog__headerbtn {
		top: 25px;
	}

	.el-dialog__wrapper>>>.el-form-item {
		margin-bottom: 0px;
	}

	.pdfClass >>> .el-dialog__body{
		background-color: #313331;
	}

	.el-dialog__wrapper>>>.el-dialog__body {
		padding: 0px 20px 20px 20px;
	}
	.el-dialog__wrapper{
		background-color:rgb(17 17 17 / 70%);
	}

	.clear {
		clear: both;
	}

	.iconfont {
		font-family: 'iconfont';
		font-style: normal;
	}

	.map>>>.ol-zoom {
		top: auto;
		left: auto;
		bottom: .5em;
		right: .5em
	}

	.map>>>.ol-scale-line-inner {
		border: 1px solid #2770D7;
		color: #2770D7;
		border-top: none;
	}

	.map>>>.ol-scale-line {
		background: #fff;
	}

	.map-container {
		position: relative;
		height: 100%;
	}

	.map {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.map .mouse-position,
	.map .scaleLine {
		position: absolute;
		padding: 5px;
		font-size: 12px;
		border-radius: 5px;
		bottom: 10px;
		left: 10px;
		box-sizing: border-box;
		z-index: 1;
	}

	.map .mouse-position {
		background: rgba(255, 255, 255, 0.9);
		color: #333;
	}

	.map .scaleLine {
		left: 95px;
		bottom: 2px;
	}

	.main-container {
		height: 100%;
	}

	.main-container .ship-detail {
		z-index: 1;
		position: absolute;
		left: 20px;
		top: 73px;
		width: 400px;
		min-height: 180px;
		background: white;
		color: black;
		border-radius: 4px;
	}

	.main-container .ship-detail .header {
		background: #0F89F5;
		height: 35px;
		line-height: 35px;
		padding: 0px 10px;
		color: white;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	.main-container .ship-detail .title {
		float: left;
	}

	.main-container .ship-detail .close {
		float: right;
		background: url(../../assets/images/map/close.png);
		background-repeat: no-repeat;
		width: 14px;
		height: 14px;
		margin-top: 10.5px;
		cursor: pointer;
	}

	.main-container .ship-detail .content {
		font-size: 12px;
		padding: 10px;
		max-height: 500px;
		overflow-x: hidden;
		overflow-y: auto;
		border: 1px solid #DCDFE6;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.main-container .ship-detail .content .el-row {
		margin: 7px 0px;
	}

	.main-container .ship-detail .content h3 {
		margin: 5px 0px;
	}

	.main-container .ship-search {
		position: absolute;
		top: 20px;
		left: 20px;
		z-index: 1;
	}

	.main-container .toolbar-container {
		position: absolute;
		z-index: 10;
		right: 20px;
		top: 20px;
		background: #fff;
		box-shadow: 0 2px 2px rgba(0, 0, 0, .25);
		border-radius: 3px;
		overflow: hidden;
		word-break: break-word;
		white-space: nowrap;
	}

	.main-container .toolbar-container.toolbar-vertical {
		top: calc(50% - 200px);
	}

	.main-container .toolbar-container.toolbar-vertical .toolbar-item {
		display: block;
		border-bottom: 1px #eee dashed;
		border-left: none;
		padding: 8px;
	}

	.main-container .toolbar-container.toolbar-vertical .toolbar-item:last-child {
		border: 0px;
	}

	.main-container .toolbar-container .toolbar-item {
		display: inline-block;
		color: #999;
		font-size: 14px;
		padding: 8px 11px;
		cursor: pointer;
		border-left: none;
	}

	.main-container .toolbar-container .toolbar-item.selected,
	.main-container .toolbar-container .toolbar-item:hover {
		color: #2770D7;
		font-weight: bold;
	}

	.main-container .toolbar-container .toolbar-item i {
		font-family: 'iconfont';
		display: inline-block;
		height: 22px;
		line-height: 22px;
		font-style: normal;
	}

	.main-container .toolbar-container .toolbar-item {
		border-left: 1px #eee dashed;
	}

	.main-container .toolbar-container .toolbar-item span {
		margin-left: 5px;
		-webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	}

	.main-container .toolbar-container .toolbar-item:first-child {
		border-left: none;
	}
	.ship-personnel{
		overflow-x: hidden;
	}
	.ship-personnel-date{
		font-size: 12px;
		margin-top: -4px;
		font-weight:normal;
	}

	.main-container .beach-risk-detail {
		z-index: 1;
		position: absolute;
		left: 20px;
		top: 73px;
		width: 800px;
		min-height: 180px;
		background: white;
		color: black;
		border-radius: 4px;
	}

	.beach-risk-detail img{
		width: 765px;
	}

	.main-container .beach-risk-detail .header {
		background: #0F89F5;
		height: 35px;
		line-height: 35px;
		padding: 0px 10px;
		color: white;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	.main-container .beach-risk-detail .title {
		float: left;
	}

	.main-container .beach-risk-detail .title {
		float: left;
	}

	.main-container .beach-risk-detail .close {
		float: right;
		background: url(../../assets/images/map/close.png);
		background-repeat: no-repeat;
		width: 14px;
		height: 14px;
		margin-top: 10.5px;
		cursor: pointer;
	}

	.main-container .beach-risk-detail .content {
		font-size: 17px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 10px;
		max-height: 500px;
		overflow-x: hidden;
		overflow-y: auto;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.selectClass:hover{
		color:#2770D7;
	}
	.active_class2{
		padding: 0 3px 3px 3px;
	}
</style>
