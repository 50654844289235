<template>
  <div id="app">
    <video-player
      v-if="videoUrl"
      :server="server"
      :video-id="id"
      :video-url="videoUrl"
      :muted="true"
      :display-title="title"
    />
  </div>
</template>

<style scoped>
  #app >>> .video-toolbox {
    display: none;
  }
</style>

<script>
import VideoPlayer from './components/Video/video-player.vue'
export default {
  name: 'Player',
  props: {
    id: String,
    title: String
  },
  watch: {
    id() {
      this.videoUrl = this.videos.filter(it => it.id === this.id)[0]?.videoUrl
    }
  },
  components: {
    VideoPlayer
  },
  data() {
    return {
      videos: '',
      server: process.env.VUE_APP_VIDEO_WEBSOCKET_SERVER,
      videoUrl: ''
    }
  },
  async created() {
    const data = await this.$get('camera/list', {})
    this.videos = data.data.map(it => {
      return {
        'device_type': 3,
        'name': it.name,
        'videoId': it.cameraId,
        'videoUrl': it.videoUrl,
        'id': it.cameraId,
        status: it.deviceStatus,
        'isControl': it.isControl === 1
      }
    })
    this.videoUrl = this.videos.filter(it => it.id === this.id)[0]?.videoUrl
    console.log(this.videoUrl)
  },
  methods: {

  }
}
</script>
