<template>
  <div class="charts-wrapper">
    <div :style="chartsStyle" v-for="(item,i) of chartCount" :key="i" class="materialCharts"></div>
  </div>
</template>

<style scoped>
.charts-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
}
</style>

<script>
  import * as echarts from 'echarts';

  var _this
  export default {
    props: {
      chartsStyle: {
        type: String,
        default: 'width: 49%;height: 98%;float: left;margin-top: 2%;'
      },
      chartCount: {
        type:Number,
        default:2
      }
    },
    data() {
      return {
        materialList: ''
      }
    },
    created() {
      _this = this
    },
    mounted() {
      this.loadMaterialCharts()
    },
    methods: {
      async loadMaterialCharts() {
        await this.$get('material/getMaterialCount').then(res => {
          this.materialList = res.data
          this.setMaterialCharts()
        })
      },
      setMaterialCharts() {
        Array.from(document.getElementsByClassName('materialCharts')).forEach(function(e, i){
          if(i > _this.chartCount){
            return false
          }
          var myChart = echarts.init(e)
          let material = _this.materialList[i]
					myChart.setOption({
            title: {
              text: material.name,
              left: 'center',
              textStyle: {
              color: '#fff',
              fontSize: 14
            }
            },
            grid: {
              top: '20%',
                left: '3%',
                right: '4%',
                bottom: '1%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: ['总需求', '进场总值', '出场总值'],
                axisLabel: {
                      show: true,
                      margin: 20,
                      textStyle: {
                          fontSize: 10,
                          color: 'white'
                      }
                  }
            },
            yAxis: {
              name: material.unit,
							nameTextStyle: {
								fontSize: 12,
								color: '#0F8CDC'
							},
              type: 'value',
              axisLabel: {
                  show: true,
                  margin: 0,
                  textStyle: {
                      fontSize: 12,
                      color: '#0F8CDC'
                  }
              },
              splitLine: {
                show:true,
                lineStyle: {
                    color: '#0D3253'
                }
              }
            },
            series: [{
                data: [material.demandCount, material.inMaterialCount, material.outMaterialCount],
                type: 'bar',
                barWidth: 15,
                itemStyle: {
                      normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                          offset: 0,
                          color: '#0F8CDC'
                        }, {
                          offset: 1,
                          color: 'rgba(15,140,220,0.1)'
                        }]),
                        borderRadius: 15
                      }
                    },
                label: {
                  show: true,
                  fontSize: 12,
                        color: 'white',
                        position: 'top'
                }
            }]
					})
        })
      }
    }
  }
</script>

<style scoped>
  
</style>