import {Circle, LineString, Point, Polygon} from 'ol/geom'
import Feature from 'ol/Feature'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import {Fill, Stroke, Style, Text} from 'ol/style'
import DxfParser from 'dxf-parser'
import request from '@/utils/request'

const parser = new DxfParser();
const colorToHex = value => {
    if (value !== undefined && !isNaN(value)) {
        var ret = '000000' + value.toString(16);
        return '#' + ret.substring(ret.length - 6);
    }
    return undefined;
}
const displayTextResoultion = 1.95 * 2;
const displayTextMaxResoultion = 0.94 * 2;
const source = new VectorSource();
const layer = new VectorLayer({
    source,
    zIndex: 1,
    style: (feature, resoultion) => {
        let entity = feature.get('entity');
        let lineStrokeWidth = 1;
        let geometry = feature.getGeometry();
        if (resoultion <= 0.94 * 2) {
            lineStrokeWidth = 2;
        }
        if (geometry instanceof LineString) {
            return new Style({
                stroke: new Stroke({
                    color: colorToHex(entity.color),
                    width: lineStrokeWidth,
                    lineDash: entity.lineType === 'OV_DASH' ? [5, 5] : undefined
                })
            })
        } else if (geometry instanceof Polygon) {
            return new Style({
                fill: new Fill({
                    color: colorToHex(entity.color),
                    // width: lineStrokeWidth,
                    // lineDash: entity.lineType === 'OV_DASH' ? [5, 5] : undefined
                })
            })
        } else if (geometry instanceof Point && resoultion <= displayTextResoultion) {
            return new Style({
                text: new Text({
                    text: entity.text,
                    font: entity.type === 'MTEXT' ? '48px bold 宋体' : '24px bold 宋体',
                    scale: Math.min(1, displayTextMaxResoultion / resoultion),
                    rotateWithView: true,
                    textAlign: 'left',
                    rotation: -entity.rotation * Math.PI / 180,
                    fill: new Fill({
                        color: colorToHex(entity.color)
                    }),
                    stroke: new Stroke({
                        color: '#000',
                        width: 1.5
                    })
                })
            })
        } else if (geometry instanceof Circle) {
            return new Style({
                stroke: new Stroke({
                    color: colorToHex(entity.color)
                })
            })
        }
    }
});


request({
    url: 'getDxfList'
}).then(res => {
    const { data } = res
    for(var i in data){
        request({
            url: 'requestDxf',
            params: {
                url: data[i].url
            }
        }).then(res => {
            let features = [];
            parser.parseSync(res.data).entities.forEach(entity=>{
                let geometry = null;
                if (entity.type === 'LWPOLYLINE') {
                    let coordinates = [];
                    entity.vertices.forEach(item=>coordinates.push([item.x,item.y]))
                    if (entity.shape) {
                        coordinates.push(coordinates[0]);
                        return new Polygon([coordinates])
                    }
                    geometry = new LineString(coordinates);
                } else if (entity.type === 'TEXT') {
                    geometry = new Point([entity.startPoint.x, entity.startPoint.y])
                } else if (entity.type === 'MTEXT') {
                    geometry = new Point([entity.position.x, entity.position.y])
                } else if (entity.type === 'CIRCLE') {
                    geometry = new Circle([entity.center.x, entity.center.y], entity.radius)
                } else {
                    return;
                }
                if (geometry != null) {
                    features.push(new Feature({
                        geometry: geometry.transform('EPSG:4326','EPSG:3857'),
                        entity: entity,
                        displayName: entity.text || ''
                    }));
                }
            })
            source.addFeatures(features);
        })
    }
})

export default layer