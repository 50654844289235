<template>
  <div class="charts-wrapper">
    <div :id="'progressCharts' + chartsId" class="progressCharts" :style="chartsStyle"></div>
    <div v-if="constructionProject" class="constructionProject">
      {{ constructionProject }}施工进度对比
    </div>
    <table class="progressTable">
      <tr v-if="name">
        <td align="left">
          滩险（施工区）名称：<font color="#28F2E6">{{ name }}</font>
        </td>
      </tr>
      <!-- <tr v-if="constructionProject">
        <td align="left">
          施工项目名称：<font color="#28F2E6">{{ constructionProject }}</font>
        </td>
      </tr> -->
      <tr>
        <td align="left">
          开工日期：<font color="#28F2E6">{{ commencementDate.split(' ')[0] }}</font>
        </td>
      </tr>
      <tr>
        <td align="left">
          已施工天数：<font color="#28F2E6">{{ constructionTime }}</font>
        </td>
      </tr>
      <!--<tr>
        <td align="left">
          进度分析：<font color="#28F2E6" v-html="progressStatus"></font>
        </td>
      </tr>-->
    </table>
  </div>
</template>

<style scoped>
.charts-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
}
.progressCharts{
  width: 70%;
  height: 100%;
  float: left;
}
.progressTable{
  float:left;
  width: 30%;
  line-height: 60px;
  margin-top: 67px;
  font-size: 12px;
  color: white;
}
.progressTable td{
  line-height: 20px;
}
.constructionProject {
  position: absolute;
  bottom: 0px;
  width: 70%;
  color: white;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
</style>

<script>
  import * as echarts from 'echarts';

  var _this
  export default {
    props: {
      totalPlannedDuration: {
        type: String,
        default: ''
      },
      constructionTime: {
        type: String,
        default: ''
      },
      commencementDate: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      constructionProject: {
        type: String,
        default: ''
      },
      chartsStyle: {
        type: String,
        default: ''
      },
      totalQuantities: {
        type: String,
        default: ''
      },
      totalCompleteQuantities: {
        type: String,
        default: ''
      },
      isRadio: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        progressStatus: '',
        chartsId: Math.random()
      }
    },
    created() {
      _this = this
    },
    mounted() {
      if(this.isRadio){
        var row = {
					totalQuantities: this.totalQuantities,
					totalCompleteQuantities: this.totalCompleteQuantities,
					totalPlannedDuration: this.totalPlannedDuration,
					constructionTime: this.constructionTime
				}
        this.loadCharts(row)
      } else {
        this.loadProgressCharts()
      }
    },
    methods: {
      async loadProgressCharts() {
        var outputs = []
        await this.$get('progress/getOutputValueByName', {name: this.name}).then(res => {
          outputs = res.data
        })
        var row = {
					totalQuantities: outputs.reduce((total, a) => { return (total + Number(a.planOut))},0) / 10000,
					totalCompleteQuantities: outputs.reduce((total, a) => { return (total + Number(a.actualOut))},0) / 10000,
					totalPlannedDuration: this.totalPlannedDuration,
					constructionTime: this.constructionTime
				}
        this.loadCharts(row)
      },
      loadCharts(row) {
        var myChart = echarts.init(document.getElementById('progressCharts' + this.chartsId))
        var dayMinQuantities = row.totalQuantities / row.totalPlannedDuration
        this.progressStatus = (row.constructionTime * dayMinQuantities) > row.totalCompleteQuantities ? '<font color="red">滞后</font>' : '<font color="green">正常</font>'
        var data = [{
            name: "计划施工",
            value: row.totalQuantities
          },
          {
            name: "实际施工",
            value: row.totalCompleteQuantities
          }
				];
				var xAxisData = [];
				var seriesData1 = [];
				// var sum = 0;
				var barTopColor = ["#02c3f1", "#53e568"];
				var barBottomColor = ["rgba(2,195,241,0.1)", "rgba(83, 229, 104, 0.1)"];
				data.forEach(item => {
          xAxisData.push(item.name);
          seriesData1.push(item.value);
          // sum += item.value;
				});
				var option = {
          backgroundColor: 'transparent',
          grid:{
              top:'20%',
              bottom:'15%'
          },
          xAxis: {
              data: xAxisData,
              axisTick: {
                  show: false
              },
              axisLine: {
                  show: false
              },
              axisLabel: {
                  show: false
              },
              interval: 0
          },
          yAxis: {
              splitLine: {
                  show: false
              },
              axisTick: {
                  show: false
              },
              axisLine: {
                  show: false
              },
              axisLabel: {
                  show: false
              }
          },
          series: [{
              name: '柱顶部',
              type: 'pictorialBar',
              symbolSize: [20, 10],
              symbolOffset: [0, -5],
              z: 12,
              itemStyle: {
                  normal: {
                      color: function(params) {
                          return barTopColor[params.dataIndex];
                      }
                  }
              },
              label: {
                  show: true,
                  position: 'top',
                  fontSize: 12,
                  offset: [0, 0],
                  formatter: function(params) {
                    var unit = '万'
                    if(params.value >= 10000){
                      params.value = (params.value / 10000).toFixed(2)
                      unit = '亿'
                    }else{
                      params.value = params.value == 'undefined' ? 0 : (params.value*1).toFixed(2)
                    }
                    return params.name + ' ' + params.value + (_this.isRadio ? 'm³' : unit) +'/' + (params.dataIndex === 0 ? row.totalPlannedDuration : row.constructionTime) + '天';
                  },
                  color: '#fff'
              },
              symbolPosition: 'end',
              data: seriesData1,
          }, {
              name: '柱底部',
              type: 'pictorialBar',
              symbolSize: [20, 10],
              symbolOffset: [0, 5],
              z: 12,
              itemStyle: {
                  normal: {
                      color: function(params) {
                          return barTopColor[params.dataIndex];
                      }
                  }
              },
              data: seriesData1
          }, {
              name: '第一圈',
              type: 'pictorialBar',
              symbolSize: [40, 20],
              symbolOffset: [0, 15],
              z: 11,
              itemStyle: {
                  normal: {
                      color: 'transparent',
                      borderColor: '#3ACDC5',
                      borderWidth: 10
                  }
              },
              data: seriesData1
          }, {
              name: '第二圈',
              type: 'pictorialBar',
              symbolSize: [60, 30],
              symbolOffset: [0, 22],
              z: 10,
              itemStyle: {
                  normal: {
                      color: 'transparent',
                      borderColor: barTopColor[0],
                      borderWidth: 10
                  }
              },
              data: seriesData1
          }, {
              type: 'bar',
              itemStyle: {
                  normal: {
                      color: function(params) {
                          return new echarts.graphic.LinearGradient(
                              0, 0, 0, 1,
                              [{
                                      offset: 1,
                                      color: barTopColor[params.dataIndex]
                                  },
                                  {
                                      offset: 0,
                                      color: barBottomColor[params.dataIndex]
                                  }
                              ]
                          );
                      },
                      opacity: 0.8
                  }
              },
              z: 16,
              silent: true,
              barWidth: 20,
              barGap: '-100%', // Make series be overlap
              data: seriesData1
          }]
				};
        myChart.setOption(option)
      }
    }
  }
</script>

<style scoped>
  
</style>